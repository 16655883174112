$text: #424242;
$gray: #808080;
$light-grey: #e8e8e8;
$light-grey2: #f6f6f6;
$error: #ff4343;
$white: #fff;
$blue: #0b6565;
$blue-hover: #04888c;
$blue-light: #26afaf;
$burger-menu: #26b0b0;
$opacity-block: #0b0b0b;

$orange: #ffc7c7;
$brown: #ffd5b4;
$yellow: #fff8a7;
$green-light: #e2f0b6;
$lazure: #a7ffef;
$lazure-blue: #bdd4ff;
$pink: #ebb7ff;
