@media all and (max-width: 425px) {
	.subscribe-popup {
		padding: 0;

		.subscribe-error-box {
			top: 205px;
			width: 100%;
			text-align: center;
		}
	}

	.subscribe-form {
		input {
			width: 290px;
		}
	}
}
