@media all and (max-width: 650px) {

	.thanks-popup {
		padding: 50px 20px;
		min-width: 390px;
	}
}

@media all and (max-width: 425px) {

	.thanks-popup {
		min-width: 320px;
	}

	.thanks-head {
		margin-top: 65px;
	}
}
