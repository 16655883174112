@media all and (max-width: 425px) {

	.contact_head {
		padding: 30px;
	}

	.contact_faq,
	.contact_form {
		h2 {
			margin-left: 15px;
			color: $blue;
			font: bold 24px/30px "Proxima Nova", Arial;
		}
	}

	.contact_form {
		width: 100%;
		padding: 0 15px;

		h2 {
			margin-left: 0;
		}

		.form-error-box {
			padding-left: 15px;
		}
	}

	.contact_faq_acc {
		margin: 0 15px;
	}

	.contact_acc__body {
		padding-right: 15px;
	}


}
