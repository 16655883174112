
.thanks-popup {
	position: relative;
	max-width: 587px;
	margin: 0 auto;
	padding: 60px 100px 65px;
	background-color: $white;
	text-align: center;
}

.thanks-head {
	margin-bottom: 33px;
	font: bold 30px/35px "Proxima Nova";
	color: $blue;
	letter-spacing: -.1px;
}

.thanks-body {
	font: normal 14px/22px "Roboto";
	color: $text;
	letter-spacing: .2px;
}
