/* Catamaran */
.top_box__shadow-bt.bg-cat__changeble {
	background-size: cover;
}

.cat_info {
	width: 100%;
	max-width: 700px;
	margin: 0 auto 65px;
	padding-left: 25px;
}

.cat_info h3 {
	margin: 0 0 2px;
	color: $blue;
	font: bold 30px/35px "Proxima Nova", Arial;
}

.cat_info h4 {
	margin: 0 0 30px;
	color: $text;
	font: bold 15px/22px "Roboto Cond", Arial;
	text-transform: uppercase;
}

.cat_info p {
	margin: 0 0 10px;
	color: $text;
	font: normal 14px/22px "Roboto", Arial;
	letter-spacing: .2px;
}

.cat_specs {
	width: 100%;
	max-width: 770px;
	margin: 0 auto 50px;
	padding-left: 55px;
}

.cat_specs__item {
	width: 28%;
	margin-right: 7%;
	float: left;
}

.cat_specs__item:last-child {
	margin-right: 0;
}

.cat_specs__item dt {
	width: 55%;
	padding: 18px 0;
	float: left;
	color: $gray;
	font: normal 11px/100% "Roboto", Arial;
}

.dl-wrap {
	border-top: 1px solid $light-grey;

	&:last-child {
		border-bottom: 1px solid $light-grey;
	}

}

.cat_specs__item dd {
	width: 45%;
	padding: 18px 0;
	float: left;
	color: $text;
	font: normal 11px/100% "Roboto", Arial;
}

.cat_more {
	padding: 5px 0 30px;
	transition: all 250ms ease;
	overflow: hidden;
}

.cat_more--active {
	margin: 0 0 70px;

	background-color: $light-grey2;
}

.cat_more__btn {
	display: block;
	margin: 0 auto 50px;
	transition: all 200ms ease;
	outline: none;
	background-color: transparent;
	color: $text;
	font: bold 10px/21px "Roboto Cond", Arial;
	letter-spacing: 1px;
	cursor: pointer;
	overflow: hidden;
}

.cat_more__btn:hover {
	color: $blue-hover;
}

.cat_more--active .cat_more__btn:hover,
.cat_more--active .cat_more__btn {
	color: $light-grey2;
}

.cat_more__btn span {
	display: block;
	position: relative;
	width: 19px;
	height: 19px;
	margin: 10px auto 0;
	transition: all 200ms ease-out;
	border: 1px solid $gray;
	border-radius: 50%;
	text-indent: -9999px;
}

.cat_more__btn:hover span {
	transform: rotate(180deg);
	border-color: $blue-hover;
	background-color: $blue-hover;
}

.cat_more--active .cat_more__btn span {
	transform: rotate(45deg);
}

.cat_more__btn span:before,
.cat_more__btn span::after {
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	background-color: $blue;
	content: ' ';
}

.cat_more__btn span::before {
	width: 7px;
	height: 1px;
}

.cat_more__btn span::after {
	width: 1px;
	height: 7px;
}

.cat_more__btn:hover span::before,
.cat_more__btn:hover span::after {
	background-color: $white;
}

.cat_more__in {
	display: none;
	width: 100%;
	max-width: 770px;
	margin: 0 auto 45px;
	padding-left: 55px;
}

@keyframes fadein{
from {opacity: 0}
to {opacity: 1}
}

.cat_more_acc {
	opacity: 0;
}

.cat_more--active .cat_more_acc {
	animation: fadein 700ms ease 500ms both;
}

.cat_more_acc__item {
	margin: 0 0 48px;
}

.cat_more_acc__title h4 {
	display: inline-block;
	position: relative;
	padding: 0 0 0 30px;
	color: $text;
	font: bold 15px/22px "Roboto Cond", Arial;
	text-transform: uppercase;
	cursor: pointer;
}

.cat_more_acc__title h4:hover {
	color: $blue-hover;
}

.cat_more_acc__title h4:before {
	display: block;
	position: absolute;
	top: 10px;
	left: 2px;
	width: 5px;
	height: 1px;
	background-color: $blue;
	content: ' ';
}

.cat_more_acc__title h4::after {
	display: block;
	position: absolute;
	top: 8px;
	left: 4px;
	width: 1px;
	height: 5px;
	transition: transform 250ms ease-out;
	background-color: $blue;
	content: ' ';
}

.cat_more_acc__title h4:hover:before {
	left: 1px;
	width: 7px;
}

.cat_more_acc__title h4:hover::after {
	top: 7px;
	height: 7px;
}

.cat_more_acc__item--active .cat_more_acc__title h4::after {
	transform: rotate(90deg);
}

.cat_more_acc__body {
	display: none;
	padding: 35px 0 0 30px;
	overflow: hidden;
}

.cat_more_acc__body p {
	margin: 0 0 15px;
	color: $text;
	font: normal 14px/22px "Roboto", Arial;
	letter-spacing: .2px;
}

.cat_more_acc__body .cat_specs__item {
	width: 46%;
}



/* Cat video box */
.cat_video_box {
	position: relative;
	width: 100%;
	max-width: 930px;
	margin: 0 auto 70px;
}

.cat_video_box__thumb {
	max-width: 82%;
}

.cat_video_box__thumb .video_container {
	height: 447px;
	overflow: hidden;
}

.cat_video_box__thumb video {
	width: 100%;
	height: 100%;
}

.cat_video_box__body {
	display: block;
	position: absolute;
	top: 50%;
	right: 0;
	width: 58%;
	height: 200px;
	background-color: $light-grey2;
	transform: translate(0, -50%);
}

.cat_video_box__body-in {
	position: relative;
	top: 50%;
	padding-left: 125px;
	transform: translate(0, -50%);
}

.cat_video_box__body-in {
	.play-btn {
		position: absolute;
		left: 40px;
		top: 0;
		bottom: 0;
		width: 43px;
		height: 43px;
		margin: auto;
	}
}

.cat_video_box__body-in h5 {
	margin: 0 0 20px;
	color: $gray;
	font: normal 11px/100% "Roboto", Arial;
}

.cat_video_box__body-in h3 {
	color: $text;
	font: bold 30px/100% "Proxima Nova", Arial;
}

/* Cat photo gal */
.cat_photo_box {
	position: relative;
	width: 100%;
	max-width: 931px;
	margin: 0 auto 70px;
	padding: 0 0 65px;
}

.cat_photo_box__ctrl {
	position: absolute;
	bottom: 0;
	left: 0;

	.arrow {
		position: absolute;
    top: 8px;
    left: 16px;
    width: 24%;
    z-index: 1;
	}

	.btn-bg {
		position: absolute;
		top: 0;
		width: 0;
		height: 100%;
		background: $blue-hover;
		z-index: 0;
	}

	.next {
		left: 0;
	}

	.prev {
		.btn-bg {
			right: 0;
		}
	}

	svg {
		overflow: visible;
	}
}

.cat_photo_box__ctrl ul {
	margin: 0;
	padding: 0 0 0 1px;
	list-style: none;
}

.cat_photo_box__ctrl ul li {
	position: relative;
	width: 44px;
	height: 40px;
	margin: 0 0 0 -1px;
	float: left;
	border: 1px solid $blue;
	cursor: pointer;
}

.cat_photo_box__ctrl ul li:hover {
	z-index: 2;
}

.zoom {
	.zoom-img {
		width: 100%;
		height: 0;
		bottom: 0;
		background: $blue-hover;
		svg {
			position: absolute;
			top: 0;
			left: 0;
			width: 42px;
		}
	}
}

.cat_photo_box__slider {
	max-width: 50%;
}

.cat_photo_box img {
  height: 100vh;
	max-height: 447px;
	object-fit: cover;
}

.cat_photo_box__item h4,
.cat_photo_box__item p {
	display: none;
}

.cat_photo_box__body {
	display: block;
	position: absolute;
	top: 50%;
	right: 0;
	width: 58%;
	height: 275px;
	transform: translate(0, -50%);
	background-color: $light-grey2;
	z-index: 2;
}

.cat_photo_box__count {
	position: absolute;
	top: 50%;
	left: 28px;
	width: 60px;
	transform: translate(0, -50%);
	color: $gray;
	font: normal 9px/27px "Roboto", Arial;
	text-align: center;
	z-index: 2;
}

.cat_photo_box__count .index {
	display: inline-block;
	color: $blue;
	font: 300 20px/35px "Proxima Nova", Arial;
	vertical-align: middle;
}

.cat_photo_box__body-in {
	position: relative;
	top: 50%;
	padding-right: 60px;
	padding-left: 125px;
	transform: translate(0,-50%);
}

.cat_photo_box__body-in h4 {
	margin: 0 0 15px;
	color: $text;
	font: bold 15px/22px "Roboto Cond", Arial;
	text-transform: uppercase;
}

.cat_photo_box__body-in p {
	max-width: 285px;
	color: $text;
	font: normal 14px/22px "Roboto", Arial;
	letter-spacing: .2px;
}

/* Bt book */
.bt__book {
	margin: 0 0 70px;
	text-align: center;
}
