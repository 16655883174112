// Fade In Body

.fadeInBody {
	opacity: 1;
}

//Fade In Blocks

.animated {
  animation-duration: 1200ms;
  animation-fill-mode: both;
	animation-timing-function: ease-out;
}

.fadeInUp {
	animation-name: fadeInUp;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
  }

	50% {
		opacity: 1;
	}

  100% {
    transform: none;
  }
}



// Burger Menu

#nav-icon {
	position: relative;
	width: 48px;
	height: 36px;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;

	span {
		display: block;
		position: absolute;
		height: 2px;
		width: 16px;
		background: $blue;
		opacity: 1;
		left: 16px;
		transform: rotate(0deg);
		transition: .25s ease-in-out;
	}
}

#nav-icon span:nth-child(1) {
  top: 14px;
}

#nav-icon span:nth-child(2),#nav-icon span:nth-child(3) {
  top: 18px;
}

#nav-icon span:nth-child(4) {
  top: 22px;
}

#nav-icon.open span:nth-child(1) {
  top: 20px;
  width: 0%;
  left: 50%;
}

#nav-icon.open span:nth-child(2) {
  transform: rotate(45deg);
}

#nav-icon.open span:nth-child(3) {
  transform: rotate(-45deg);
}

#nav-icon.open span:nth-child(4) {
  top: 20px;
  width: 0%;
  left: 50%;
}

/* Scroll bottom */
@keyframes bounce {

	0% {
		height: 12px;
	}

	50% {
		height: 16px;
	}

	100% {
		height: 12px;
	}
}

.scrollbt {
	display: block;
	position: absolute;
	right: 0;
	bottom: 30px;
	left: 0;
	width: 18px;
	height: 26px;
	margin: auto;
	z-index: 2;
	cursor: pointer;
}

// Popup Animation

.mfp-zoom-in {

  /* start state */
  .mfp-with-anim {
    opacity: 0;
    transition: all 0.4s ease-in-out;
    transform: scale(0.8);
  }

  &.mfp-bg {
    opacity: 0;
	  transition: all 0.5s ease-out;
  }

  /* animate in */
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: scale(1);
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }

  /* animate out */
  &.mfp-removing {

    .mfp-with-anim {
      transform: scale(0.8);
      opacity: 0;
    }
    &.mfp-bg {
      opacity: 0;
    }

  }

}
.mfp-video-in {

  /* start state */
  .mfp-with-anim {
    opacity: 1;
    transition: all 0.4s ease-in-out;
    transform: scale(0.65);
  }

  &.mfp-bg {
    opacity: 0;
	  transition: all 0.5s ease-out;
  }

  /* animate in */
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: scale(1);
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }

  /* animate out */
  &.mfp-removing {

    .mfp-with-anim {
      transform: scale(0.65);
      opacity: 1;
    }
    &.mfp-bg {
      opacity: 0;
    }

  }

}
.cat_photo_box__body.opacity,
.cat_video_box__body.opacity {
	opacity: 0;
	transition: opacity ease-in 150ms;
}
.cat_photo_box__body,
.cat_video_box__body {
	opacity: 1;
	transition: opacity ease-out 500ms;
}
