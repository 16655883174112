@media all and (max-width: 950px) {
	.blog-article {
		.routes_box__legend {
			flex-direction: column-reverse;
			padding-left: 15px;
		}
		.route-distance {
			margin-bottom: 20px;
			padding-bottom: 20px;
			padding-left: 0;
			width: 100%;
			border-bottom: 2px solid #e8e8e8;
			border-left: none;

			input {
				text-align: left;
			}
		}
		.list-unstyled {
			margin-left: 0 !important;
		}
	}
}

@media all and (max-width: 767px) {
	.wrapper {
		padding: 0 15px;
	}
	.blog-article {
		&-header,
		&__text,
		.text-list,
		&__subheading {
			margin-left: 0;
		}
		.cat_video_box__body {
			padding-right: 0;
		}
		.slider-copyright {
			margin-bottom: 50px;
		}
		.cat_video_box {
			margin-bottom: 40px;
			padding-left: 0 !important;
		}
		.cat_video_box__body {
			position: relative;
			margin: -90px 0 0 auto;
			transform: translate(0%, 0%)!important;
		}
	}
	.photo-widget .cat_photo_box {
		margin-bottom: 50px;
	}

	.article-list__item {
		width: 100%;
	}
	.quote {
		margin-left: 0;
		&-text {
			padding: 0 43px;
		}
	}
	.text-list-widget {
		padding-left: 42px;
	}
	.blog-image__slider {
		width: 100%;
	}
	.article-list {
		position: relative;
		max-width: 340px;
		.owl-nav {
			position: absolute;
			top: 0;
			right: 0;
		}
		&__item {
			padding-top: 80px;
			padding-left: 0;
		}
	}
}

@media all and (max-width: 600px) {
	.quote {
		flex-direction: column;
		&:before {
			width: 100%;
			height: 105px;
		}
		&-text {
			&:before {
				top: auto;
				left: auto;
				right: 14px;
				bottom: -30px;
			}
		}
		&-img__wrap {
			margin-bottom: 35px;
			margin-left: 43px;
		}
		&-author {
			text-align: left;
		}
	}
	.photo-copyright-widget {
		.cat_photo_box {
			flex-direction: column;
			max-width: 320px;
			padding-bottom: 0;
		}
		.cat_photo_box__slider {
			margin-bottom: 50px;
			max-width: 100%;
		}
		.cat_photo_box__ctrl {
			top: 260px;
			right: 0;
			left: auto;
		}
		.cat_photo_box__body {
			width: 100%;
		}
		.cat_photo_box__body-in {
			max-width: 100%;
			top: 0 !important;
		}
	}
}

@media all and (max-width: 500px) {
	.blog-article {
		.cat_video_box__body-in {
			padding: 90px 15px 25px 43px;
			.play-btn {
				top:30px;
				bottom: auto;
			}
		}
	}
	.blog-article-map {
		width: 100%;
	}
	.blog-image__slider {
		max-width: 100%;
	}
	//.photo-copyright-widget {
	//	left: 0;
	//	bottom: 0;
	//}
	.photo-comments-widget {
		.cat_photo_box__body {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: -30px;
			margin-left: auto;
			min-height: 275px;
			height: auto;
			padding-top: 80px;
			padding-bottom: 20px;
		}
		.cat_photo_box__body-in {
			top: 0;
			transform: translate(0, 0);
		}
		.cat_photo_box__slider {
			max-width: 320px;
		}
		.cat_photo_box__ctrl {
			top: 240px;
			right: 15px;
			left: auto;
			bottom: auto;
			z-index: 2;
		}
		.cat_photo_box {
			margin-bottom: 40px;
			padding-bottom: 0;
		}
	}
	.photo-widget {
		.wrapper {
			padding-left: 0;
		}
		.cat_photo_box {
			padding-left: 0;
		}
		.cat_photo_box__ctrl {
			left: auto;
			right: 0;
			bottom: 0;
		}
	}
}
@media all and (max-width: 425px) {
	.blog-article {
		.cat_video_box__body {
			margin-top: -30px;
			margin-right: -15px;
		}
		.cat_photo_box {
			padding-left: 0;
		}
		.route-distance {
			padding-top: 25px;
		}
		.routes_box__body {
			height: 345px;
		}
		.routes_box__info--active.routes_box__info--active-mobile {
			max-width: 100%;
			padding: 15px;
			height: auto;
			transform: translate(0%, 100%);
			bottom: 0;
			left: 0;
			top: auto;
			background: #fff;
		}
		.routes_box__body--active .routes_box__map, .routes_box__body--active .blog-article-map {
			width: 100%;
		}
	}
	.photo-copyright-widget {
		.wrapper {
			padding-left: 0;
		}
		.cat_photo_box {
			max-width: 100%;
			width: 100%;
		}
		.cat_photo_box__slider {
			margin-left: -1px;
		}
	}
	.photo-widget .cat_photo_box__slider {
		margin-left: -1px;
	}
	.photo-comments-widget {
		.cat_photo_box__body {
			margin-right: -15px;
		}
	}
}
