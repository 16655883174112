.wrapper {
  margin: 0 auto;
  max-width: 1024px;
  width: 100%;
  padding: 0 48px 0 46px;
}
.blog {
  .news__column {
    margin-bottom: 60px;
  }
  &-filter {
    display: flex;
    margin-top: 30px;
    width: 100%;
    &__item {
      margin-right: 40px;
    }
    &__link {
      font: normal 14px/22px "Roboto";
      letter-spacing: 0.2px;
      text-decoration: none;
      color: #424242;
      &--active {
        color: #04888c;
      }
    }
  }
  .btn-wrap {
    margin-top: 0;
    width: 100%;
  }
  &-more__news {
    outline: none;
    border: none;
    font: 600 25px/35px "Proxima Nova";
    color: #04888c;
    background: transparent;
  }
}
.blog-article {
  padding-bottom: 95px;
  font: normal 14px/22px 'Roboto';
  color: #424242;
  &-top {
    position: relative;
    padding-top: 130px;
    padding-bottom: 60px;
    &--img {
      padding-top: 300px;
    }
    &--img:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      //background: rgba(255, 255, 255, 0.35);
      background: linear-gradient(to bottom, rgba(255, 255, 255, .35), rgba(255, 255, 255, 1));
      z-index: -1;
    }
    &--dark:before {
      background: rgba(0, 0, 0, 0.35);
    }
  }
  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2;
  }
  &__date {
    display: block;
    margin-bottom: 37px;
  }
  &__date, .quote-author {
    font: normal 11px/18px "Roboto";
    color: #808080;
    letter-spacing: .33px;
  }
  &__heading {
    margin-bottom: 25px;
  }
  &__subheading {
    font: bold 15px/1.47 "Roboto";
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
  &-header,
  &__text,
  .text-list,
  &__subheading {
    margin-left: 13.6%;
  }
  &__text,
  &__subheading,
  .routes_box {
    margin-bottom: 50px;
  }
  &-header {
    max-width: 550px;
  }
  &__text,
  &__subheading{
    max-width: 630px;
  }
  .cat_video_box__body {
    padding-right: 75px;
  }
  .routes_box__legend {
    display: flex;
    justify-content: space-between;
    margin: 0;
    width: 85%;
  }
  .route-distance{
    display: none;
  }
  .cat_video_box__body {
    display: flex;
    align-items: center;
    min-height: 200px;
    height: auto;
  }
  .cat_video_box__body-in {
    top: 0;
    transform: translate(0%, 0%);
    width: 100%;
    height: 100%;
    padding: 25px 25px 25px 125px;
  }
  .play-btn {
    background: transparent;
  }
  .routes_box__legend ul {
    margin: 0 auto;
  }
}
.text-list-widget + * {
  margin-top: 50px;
}

.share-list__link path:not(#a) {
  fill: #04888C;
}
.share-list__link:hover path {
  opacity: .7;
}
.blog-article-top--img {
  margin-bottom: 65px;
  padding-bottom: 75px;
  .blog-article-header {
    margin-bottom: 0;
  }
}

.blog-image__slider {
  max-width: 90%;
}

.slider-copyright {
  .cat_photo_box__count {
    display: none;
  }
  .cat_photo_box__body {
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: calc(100% - 65px);
    transform: translate(0%, 0%);
    background: transparent;
  }
  .cat_photo_box__body-in {
    top: auto;
    transform: translate(0%, 0%);
    padding: 15px;
    max-width: 285px;
  }
}

.blog-article-top--dark * {
  color: #fff;
  .share-list__link path:not(#a) {
    fill: #fff;
  }
}
.blog-article-header .blog-article__subheading {
  margin-left: 0;
  margin-bottom: 40px;
}

.share-box, .share-list {
  display: flex;
  align-items: center;
}
.share-box__title {
  margin-right: 45px;
  font-size: 14px;
}
.share-list {
  justify-content: space-between;
  width: 80px;
}

.quote {
  position: relative;
  display: flex;
  margin-left: 9%;
  margin-bottom: 50px;
  max-width: 715px;
  padding: 45px 0;
  background-color: #fafafa;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 80px;
    height: 100%;
    background: #fff;
  }
  &-img__wrap {
    position: relative;
    margin-right: 35px;
    flex-shrink: 0;
    width: 160px;
    height: 160px;
  }
  &-img__item {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &-text {
    position: relative;
    margin: 5px 0;
    padding: 0 65px;
    font: bold 18px/24px 'Proxima Nova';
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 8px;
      width: 15px;
      height: 11px;
      background: url("../images/icons/quote-ico.svg") center no-repeat;
    }
  }
  &-author {
    margin-top: 15px;
    text-align: right;
  }
}
.article-list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  counter-reset: item;
  .owl-nav {
    display: flex;
  }
  .owl-prev,
  .owl-next {
    width: 40px;
    height: 40px;
    border: 1px solid #0b6565;
    font-size: 0;
    background: center no-repeat;
  }
  .owl-next {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5IiBoZWlnaHQ9IjYiIHZpZXdCb3g9IjAgMCA5IDYiPiAgICA8cGF0aCBmaWxsPSIjMEI2NTY1IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01IDB2Mi4xOTNIMHYxaDV2Mi4xOTRsMy4zNjctMi42OTN6Ii8+PC9zdmc+);
    border-left: none;
  }
  .owl-prev {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5IiBoZWlnaHQ9IjYiIHZpZXdCb3g9IjAgMCA5IDYiPiAgICA8cGF0aCBmaWxsPSIjMEI2NTY1IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zLjM2NyAwdjIuMTkzaDV2MWgtNXYyLjE5NEwwIDIuNjk0eiIvPjwvc3ZnPg==);
  }
}
.article-list__item {
  position: relative;
  margin-bottom: 50px;
  width: 44%;
  max-width: 340px;
  padding-left: 85px;
  counter-increment: item;
  list-style-type: none;
}
.article-list__item:nth-child(odd) {
  margin-right: 80px;
}
.article-list__item:before {
  content: counter(item, decimal-leading-zero) '';
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 50px;
  height: 50px;
  text-align: center;
  vertical-align: middle;
  color: #fff;
  line-height: 50px;
  background-color: #0b6565;
}
.text-list__item {
  position: relative;
  margin-bottom: 25px;
  max-width: 630px;
  list-style-type: none;
}
.text-list__item:before {
  content: '';
  position: absolute;
  left: -20px;
  top: 10px;
  display: block;
  width: 10px;
  height: 2px;
  background-color: #0b6565;
}

.article-banner {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 684px;
  min-height: 106px;
  padding: 30px 46px 13px 46px;
  background-color: #26b0b0;
  color: #fff;
  z-index: 9999;
  &-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
  }
  &__heading {
    margin-right: 30px;
    margin-bottom: 17px;
    text-transform: uppercase;
    font: normal 15px/22px "Roboto Cond";
  }
  &__link {
    position: relative;
    display: block;
    margin-bottom: 17px;
    height: 22px;
    padding-right: 25px;
    text-decoration: none;
    text-transform: uppercase;
    font: bold 10px/21px "Roboto Cond";
    letter-spacing: 1px;
    color: #fff;
      &::after,
      &::before {
        content: ' ';
        display: block;
        position: absolute;
        top: 50%;
      }
      &::after {
        right: -6px;
        width: 0;
        height: 0;
        transform: translate(0, -50%) scale(1.5, 1.1);
        border: 3px solid transparent;
        border-left-color:#333;
      }
      &::before {
        right: 0;
        width: 8px;
        height: 1.3px;
        transform: translate(0, -50%);
        transition: width 250ms ease-out;
        background-color: #333;
      }
  }
}

.close-more-menu,
.article-banner__close {
  position: absolute;
  left: 22px;
  top: 22px;
  width: 16px;
  height: 16px;
  outline: none;
  background-color: transparent;
  &:before,
  &:after {
    content: ' ';
    position: absolute;
    top: 3px;
    left: 50%;
    height: 12px;
    width: 1px;
    background-color: #333;
  }
  &:before {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}

.route-distance {
  padding-left: 20px;
  border-left: 2px solid #e8e8e8;
  label {
    cursor: default;
    letter-spacing: 0.3px;
    color: #808080;
  }
  input {
    width: 80px;
    text-align: right;
    font: bold 15px/22px "Roboto Cond";
    color: #0b6565;
  }
}

.place {
  &-heading {
    margin-bottom: 5px;
    letter-spacing: 0.4px;
    text-align: center;
    font: bold 15px/22px "Roboto Cond";
    color: #0b6565;
  }
  &-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &-image__wrap {
    margin-bottom: 11px;
    width: 207px;
    height: 106px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__link {
    position: relative;
    padding-right: 25px;
    text-decoration: none;
    text-transform: uppercase;
    font: bold 10px/21px "Roboto Cond";
    background: transparent;
    outline: none;
    color: #424242;
    &::after,
    &::before {
      content: ' ';
      display: block;
      position: absolute;
      top: 50%;
    }
    &::after {
      right: -6px;
      width: 0;
      height: 0;
      transform: translate(0, -50%) scale(1.5, 1.1);
      border: 3px solid transparent;
      border-left-color:#0b6565;
    }
    &::before {
      right: 0;
      width: 8px;
      height: 1.3px;
      transform: translate(0, -50%);
      transition: width 250ms ease-out;
      background-color: #0b6565;
    }
  }
}
.photo-copyright-widget {
    .cat_photo_box {
      display: flex;
      align-items: center;
      &__body {
        position: relative;
      }
    }
}
.cat_photo_box__ctrl.opacity {
  opacity: 0;
}
.blog-article-map .gm-style-iw {
  left: 25px !important;
}