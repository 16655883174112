
/* Typography */

.header-lead {
	font: bold 45px/47px "Proxima Nova";
	color: $blue;
	letter-spacing: -.1px;
}
.header-contrast {
	font: bold 30px/35px "Proxima Nova";
	color: $blue;
}

.header-text {
	font: bold 18px/24px "Proxima Nova";
	color: $text;
}
.link-text-big {
	font: bold 15px/35px "Roboto Cond";
	color: $text;
	text-transform: uppercase;
	letter-spacing: .45px;
}
.link-text-small {
	font: bold 10px/21px "Roboto Cond";
	color: $text;
	text-transform: uppercase;
	letter-spacing: 1px;
}
.text {
	font: normal 14px/22px "Roboto";
	color: $text;
	letter-spacing: .2px;
}
.text-footer {
	font: bold 11px/18px "Roboto";
	color: $text;
	letter-spacing: .33px;
}
.text-footer-small {
	font: bold 9px/27px "Roboto";
	color: $gray;
	letter-spacing: .27px;
}
