@media all and (min-width: 881px) {

	.news-column {
		display: flex;
		justify-content: center;
		position: fixed;
		top: 0;
		right:0;
		width: 50%;
		height: 100vh;
		background-color: $blue-light;
		padding-right: 125px;
		padding-left: 30px;
		transform: translateX(100%);
		transition: transform .4s ease-in-out;


		.news-wrap {
			position: relative;
			align-self: center;
			padding: 40px;
			.close-more-menu {
				top: 45px;
				left: 0;
				&:before,
				&:after {
					background: #fff;
				}
			}
		}

		.news-line {
			padding: 35px 0;
			border-bottom: 1px solid $blue;

			&:last-child {
				border-bottom: 0;
			}
			a {
				color: $white;
				font: bold 15px/22px "Roboto", Arial;
				letter-spacing: .5px;
				text-transform: uppercase;
				text-decoration: none;
			}
		}
		&--active {
			transform: translateX(0);
			transition: transform .4s ease-in-out;

			.news-line {
				opacity: 1;
			}
		}
		&__heading {
			color: #fff;
		}
	}
	.top__nav--active {
		z-index: 2;
		h5,
		ul li a {
			color: #fff;
		}
		.arr-r {
			right: 20px !important;
		}
	}
	.mobile-menu {
		display: none;
	}
}

@media all and (max-width: 1070px) {
	.edge_box__in {
		margin: 0 6%;
	}
	.routes_box__title {
		margin: 0;
	}
	.news_box {
		margin: 0 8% 135px;
	}
	.comments_box__header {
		margin: 0 8% 53px;
	}
	.comments_box__slider,
	.comments_controls {
		margin: 0 8%;
	}
}

@media all and (max-width: 880px) {

	.top__logo {
		margin-left: 7%;

		img {
			max-width: 75px;
		}
	}

	.navigation {
		position: fixed;
		top: -750px;
		width: 100%;
		height: 100%;
		overflow-y: scroll;
		overflow-x: hidden;
		z-index: 7;

		.navigation-column {
			display: block;
			position: relative;
			background-color: $white;
			width: 100%;
			height: 480px;
			padding: 35px 30px;

			.top__lang {
				opacity: 0;
			}

			.top__nav {
				position: relative;
				opacity: 0;

				li {
					margin-bottom: 0;
				}

				a {
					font: bold 15px/35px "Roboto Cond";
					text-transform: uppercase;
					letter-spacing: 1.6px;
				}
			}

			.top__call {
				position: absolute;
				bottom: 10px;
				margin-left: 15px;
				opacity: 0;

				.call {
					font: bold 15px/35px "Roboto Cond";
					letter-spacing: .45px;
					vertical-align: top;
				}
			}

			.btn-green {
				width: 173px;
				height: 45px;
				margin: 15px 0;

				.arr-r {
					color: $white;
					font: bold 15px/48px "Roboto Cond";
					padding: 0 35px 0 0;
				}

				.btn-name {
					margin-top: 6px;
				}
			}
		}

		.news-column {
			position: absolute;
			top: 0;
			right: -500px;
			display: flex;
			justify-content: center;
			width: 50%;
			height: 480px;
			background-color: $blue-light;
			.close-more-menu, .news-column__heading {
				display: none;
			}

			.news-wrap {
				align-self: center;
				padding: 40px;
			}

			.news-line {
				padding: 35px 0;
				border-bottom: 1px solid $blue;

				&:last-child {
					border-bottom: 0;
				}

				a {
					color: $white;
					font: bold 15px/22px "Roboto", Arial;
					letter-spacing: .5px;
					text-transform: uppercase;
					text-decoration: none;
				}
			}
		}
	}

	.mobile-menu {
		display: block;
		position: fixed;
		top: 32px;
		right: 0;
		width: 48px;
		height: 36px;
		background-color: $blue-light;
		cursor: pointer;
		z-index: 7;
	}

	.btn-green {
		.btn-name {
			right: 45px;
		}
	}

	.top_box__video {
		display: none;
	}

	.top_box__shadow-bt {
		background-image: url('../images/bg-main.jpg');
		background-size: cover;
	}

	.info_box {
		margin-bottom: 100px;
		padding: 0 50px;
	}

	.info_box__left {
		margin-right: 90px;
		p {
			width: 75px;
		}
	}

	.info_box__body {
		h3 {
			width: 400px;
		}

		p {
			width: 458px;
		}
	}

	.cat_list {
		padding: 0;
	}

	.cat_list__items {
		margin-left: 15px;
	}

	.cat_list__item {

		.img {
			width: 377px;
			height: 367px;
		}

		.info {
			position: absolute;
			top: 0;
			left: 0;
			width: 427px;
			transform: translate(70%, 35%);
		}
	}

	.edge_box__in {
		padding-left: 50px;
		margin: 0;
	}

	.edge_box__title {
		margin: 0 0 50px 0;
	}

	.edge_box__item {
		max-width: 300px;
		min-width: 300px;
		margin: 0 40px 40px 0;
		padding: 80px 30px 45px 45px;
	}

	.routes_box {
		margin-bottom: 68px;
	}

	.routes_box__title {
		margin: 0 50px 30px;
	}

	.routes_box__map {
		width: 100%;
	}

	.routes_box__legend {
		ul {
			display: flex;
			margin-left: 90px;
		}
	}

	.news_box {
		max-width: 635px;
	}

	.news__column {
		max-width: 296px;
		margin-bottom: 50px;

		.news__image {
			margin-bottom: 18px;
			height:190px;
		}

		.news__head {
			display: block;
		}

		.news__body {
			margin-top: 28px;
		}
	}

	.comments_box__header,
	.partners_box__header {
		margin: 0 0 30px;
		padding-left: 60px;
	}

	.comments_box__item {
		.comments_box__body {
			width: 510px;
		}
	}

	.comments_box__slider {
		margin: 0;
	}

	.comments_controls {
		margin: 0;

		.comments_box__ctrl {
			left: 34%;
		}

		.comments_box__count {
			left: 12%;
		}
	}

	.partners_box__items {
		max-width: 600px;

		.partners_box__item {
			margin: 25px;
		}
	}

	.footer {
		display: flex;
		flex-direction: column;
	}

	.footer__info {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		padding: 60px 90px 65px 50px;
		background: $light-grey2;
		text-align: center;
		vertical-align: middle;
		p {
			width: 100%;
			text-align: left;
			line-height: 40px;
		}
		.btn-bordered {
			padding: 0 79px;
		}
	}

	.footer__contacts {
		padding: 60px 90px 50px 50px;

		ul {
			display: flex;
			justify-content: space-around;
		}

		.footer__contacts-phone {
			margin-right: 50px;
		}

		.footer__contacts-email {
			flex-grow: 1;
		}

		.footer__contacts-in {
			margin-right: 30px;
		}

		.footer__contacts-fb {
			margin-right: 0;
		}
	}

	.footer__subscribe {
		padding: 0 0 70px 50px;
	}

	.footer__credits {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 0 90px 0 50px;

		.footer__pay {
			margin-bottom: 35px;
		}

		.footer__copy {
			order: 1;
			flex-grow: 1;
			width: 100%;
		}

		.footer__dev {
			ul {
				li {
					&:last-child {
						margin-right: 0;
					}
				}
			}
		}
	}
}

@media all and (max-width: 750px) {
	.cat_list__item {
		margin-bottom: 115px;
		.info	 {
			transform: translate(45%, 50%);
		}
	}

	.edge_box__item {
		width: 280px;
		margin: 0 10px 10px 0;
	}

	.routes_box__info {
		width: 305px;
		padding: 20px 0 0 20px;
	}

	.comments_box__slider {
		max-width: 500px;
		margin: 0 auto;
	}

	.comments_box__item {
		.comments_box__image {
			position: static;
			transform: translate(22%, 26%);
		}
		.comments_box__body {
			padding: 85px 45px 45px;
		}
	}

	.comments_controls {
		position: static;
		.comments_box__ctrl {
			top: 260px;
	    left: 60%;
		}

		.comments_box__count {
			position: absolute;
	    top: 370px;
	    left: 390px;
	    z-index: 2;
		}
	}

	.footer__info {
		justify-content: center;
		padding: 50px 0 50px 15px;
		p {
			width: 230px;
			line-height: 20px;
		}
	}
}

@media all and (max-width: 650px) {

	.info_box {
		flex-direction: column;
	}

	.info_box__body {
		margin: 50px 0 0 70px;
		h3 {
			width: 300px;
		}
		p {
			width: 310px;
		}
	}

	.cat_list__item {
		margin-bottom: 200px;

		.info {
			width: 340px;
			padding: 0 0 0 100px;
			transform: translate(42%, 90%);

			.btn-bordered {
				left: 100px;
			}
		}

		.img {
			max-width: 380px;
		}

		.numb {
			left: 30px;
		}


	}

	.edge_box__item {
		display: none;
		width: 382px;
		height: 245px;
		margin: 0;
		padding: 63px 60px 0 85px;
		&:first-child {
			display: block;
		}

		.edge_box__ctrl {
			position: absolute;
			display: block;
			top: 5px;
			right: 10px;

			li {
				border: 0;
			}
		}
	}

	.routes_box__legend {
		ul {
			flex-direction: column;
		}
	}

	.footer__info {
		p {
			margin-bottom: 27px;
		}
	}

	.news_box {
		margin: 0;
		padding: 0 15px;
	}

	.footer__credits {
		.footer__pay {
			margin-bottom: 20px;
		}

		.footer__dev {
			margin-bottom: 15px;
			li {
				&:first-child {
					margin: 0;
				}
			}
		}
	}
}

@media all and (max-width: 500px) {

	.news__column {
		max-width: 100%;

		//.news__body {
		//	margin-left: 40px;
		//}
	}

	.info_box {
		margin: 0 15px 80px;
		padding: 0;
	}

	.cat_list__item {
		margin-bottom: 240px;

		.img {
			width: 300px;

		}

		.info {
			transform: translate(20%, 90%);
		}
	}

	.routes_box__legend {
		ul {
			margin-left: 15px;
		}
	}

	.comments_box__item {
		.comments_box__body {
			height: 390px;
		}
	}

	.footer__info {
		flex-direction: column;
		padding: 40px 0 50px 15px;
	}

	.footer__contacts {
		padding: 42px 15px 32px 15px
	}

	.footer__subscribe {
		padding: 0 0 54px 15px;
	}

	.footer__credits {
		padding: 0 80px 0 15px;
	}

}
