@media all and (max-width: 425px) {
	.top_cat_info {
		h1 {
			font: bold 30px/35px 'Proxima Nova', Arial;
			letter-spacing: -.1px;
		}

		h5 {
			margin: 0 0 5px;
		}
	}

	.top_box {
		margin: 0 0 25px;
	}

	.cat_info,
	.cat_specs {
		padding-right: 15px;
	}

	.cat_info {
		h3 {
			font: bold 24px/30px "Proxima Nova", Arial;
			letter-spacing: -.1px;
		}
	}

	.dl-wrap {
		border-bottom: 0;

		&:last-child {
			border-bottom: 0;
		}
	}

	.cat_video_box {
		margin: 0 auto 127px;
	}

	.cat_video_box {
		.video_container {
			width: 288px;
			height: 169px;
		}
	}

	.cat_video_box__body {
		width: 260px;
		height: 105px;
		transform: translate(0, 55%);
	}

	.cat_video_box__body-in {
		padding-left: 85px;

		h5 {
			margin: 0 0 12px;
		}

		h3 {
			font: bold 24px/100% "Proxima Nova", Arial;
		}

		&:before {
			left: 15px;
		}
	}

	.cat_photo_box {
		margin: 0 auto 243px;
	}

	.cat_photo_box__slider {
		max-width: 246px;
	}

	.cat_photo_box__body {
		transform: translate(0, 0);
		width: 262px;
		height: 346px;
	}

	.cat_photo_box__count {
		top: 50px;
		left: 27px;
	}

	.cat_photo_box__ctrl {
		left: 55%;
	}
}
