html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; vertical-align: baseline; }
body,html {}
img, fieldset, a img { border: 0; }
input, textarea { -webkit-appearance: none; }
textarea { overflow: auto; }
input,button { margin: 0; padding: 0; border: 0; }
input[type="submit"],
button { cursor: pointer; }
div, input, textarea, select, button, h1, h2, h3, h4, h5, h6, a, span, a:focus { outline: none; }
ul, ol { list-style-type: none; }
table { width: 100%; border-collapse: collapse; border-spacing: 0; }

html {
	position: relative;
	height: 100%;
	box-sizing: border-box;

}

*,
*::before,
*::after {
	box-sizing: inherit;
}

.cf::after {
	display: block;
	height: 0;
	clear: both;
	content: ' ';
	visibility: hidden;
}

* html .cf {zoom: 1;}
*:first-child+html .cf {zoom: 1;}

body {
	height: 100%;
	position: relative;
	-webkit-overflow-scrolling: touch;
	&.overflow{
		.body-wrap{
			height: 100%;
			position: relative;
			overflow: hidden;

		}
	}
}

.body-wrap {
	opacity: 0;
	transition: opacity 1000ms;
}

.bodymovin {
	display: none;
	position: fixed;
	width: 100vw;
	height: 100vh;
	background-color: #fff;
	z-index: 100;
}

.hidden {
	opacity: 0;
}

.visible {
	opacity: 1;
}

.overflow {
	overflow: hidden;
}

@font-face {
	font-family: 'Roboto Cond';
	src: url('../fonts/Roboto-BoldCondensed.eot');
	src: url('../fonts/Roboto-BoldCondensed.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Roboto-BoldCondensed.woff') format('woff'),
		url('../fonts/Roboto-BoldCondensed.ttf') format('truetype'),
		url('../fonts/Roboto-BoldCondensed.svg#Roboto-BoldCondensed') format('svg');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('../fonts/Roboto-Regular.eot');
	src: url('../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Roboto-Regular.woff') format('woff'),
		url('../fonts/Roboto-Regular.ttf') format('truetype'),
		url('../fonts/Roboto-Regular.svg#Roboto-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto Cond';
	src: url('../fonts/Roboto-Condensed.eot');
	src: url('../fonts/Roboto-Condensed.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Roboto-Condensed.woff') format('woff'),
		url('../fonts/Roboto-Condensed.ttf') format('truetype'),
		url('../fonts/Roboto-Condensed.svg#Roboto-Condensed') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/ProximaNova-Light.eot');
	src: url('../fonts/ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/ProximaNova-Light.woff') format('woff'),
		url('../fonts/ProximaNova-Light.ttf') format('truetype'),
		url('../fonts/ProximaNova-Light.svg#ProximaNova-Light') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/ProximaNova-Semibold.eot');
	src: url('../fonts/ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/ProximaNova-Semibold.woff') format('woff'),
		url('../fonts/ProximaNova-Semibold.ttf') format('truetype'),
		url('../fonts/ProximaNova-Semibold.svg#ProximaNova-Semibold') format('svg');
	font-weight: bold;
	font-style: normal;
}

.is-open {
	display: block !important;
}

.top_box__video {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
}

.top_box__video video {
	position: absolute;
  top: 50%;
  left: 50%;
	width: auto;
	min-width: 100%;
	height: auto;
  min-height: 100%;
  transform: translate3d(-50%,-50%,0);
}


.video-disabled {
	background-size: cover;

	video {
		display: none;
	}
}

/* Buttons */

.btn-bordered,
.btn-green {
	position: relative;
	overflow: hidden;

	.btn-name {
		position: absolute;
		top: 0;
		right: 45px;
		left: 10px;
		z-index: 1;
	}

	.arr-r {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 45px;
		z-index: 1;
	}

	.arrow {
		right: 70px;
		z-index: -1;
	}

	.btn-bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 100%;
		background: $blue-hover;
		z-index: 0;
	}
}

.btn-green {
	.btn-name {
		right: 30px;
	}
	.arr-r {
		right: 20px;
	}
}

.btn-bordered {
	display: inline-block;
	width: 160px;
	height: 40px;
	transition: all 200ms ease-out;
	border: 1px solid $blue;
	outline: none;
	background-color: $white;
	color: $text;
	font: bold 10px/40px "Roboto Cond", Arial;
	letter-spacing: 1px;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	text-transform: uppercase;

	&:hover {
		color: $white;
	}

	.arr-r {

		&::after {
			display: block;
			position: absolute;
			top: 50%;
			right: -6px;
			width: 0;
			height: 0;
			transform: translate(0, -50%) scale(1.5, 1.1);
			border: 3px solid transparent;
			border-left-color: $blue;
			content: ' ';
		}

		&::before {
			display: block;
			position: absolute;
			top: 50%;
			right: 0;
			width: 8px;
			height: 1.3px;
			transform: translate(0, -50%);
			transition: width 250ms ease-out;
			background-color: $blue;
			content: ' ';
		}
	}
}

.btn-green {
	display: inline-block;
	width: 160px;
	height: 40px;
	outline: none;
	background-color: $blue-light;
	color: $white;
	font: bold 10px/41px "Roboto Cond", Arial;
	letter-spacing: 1px;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;

	.arr-r {

		&::after {
			display: block;
			position: absolute;
			top: 50%;
			right: -6px;
			width: 0;
			height: 0;
			transform: translate(0, -50%) scale(1.5, 1.1);
			border: 3px solid transparent;
			border-left-color: $blue;
			content: ' ';
		}

		&::before {
			display: block;
			position: absolute;
			top: 50%;
			right: 0;
			width: 8px;
			height: 1.3px;
			transform: translate(0, -50%);
			transition: width 250ms ease-out;
			background-color: $blue;
			content: ' ';
		}
	}
}

.btn-more {
	display: inline-block;
	transition: all 200ms ease-out;
	outline: none;
	color: $text;
	font: bold 10px/21px "Roboto Cond", Arial;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
	transition: color ease-in-out 200ms;

	&:hover {
		color: $blue-hover;
	}
}

.comments_box__ctrl,
.comp__ctrl,
.edge_box__ctrl {

	ul {
		margin: 0;
		padding: 0 0 0 1px;
		list-style: none;

		li {
			position: relative;
			width: 44px;
			height: 40px;
			margin: 0 0 0 -1px;
			float: left;
			border: 1px solid $blue;
			cursor: pointer;

			&:hover {
				z-index: 2;
			}
		}
	}

	.arrow {
		position: absolute;
		top: 8px;
		left: 16px;
		width: 24%;
		z-index: 1;
	}

	.btn-bg {
		position: absolute;
		top: 0;
		width: 0;
		height: 100%;
		background: $blue-hover;
		z-index: 0;
	}

	.next {
		left: 0;
	}

	.prev {
		.btn-bg {
			right: 0;
		}
	}

	svg {
		overflow: visible;
	}
}

.close-popup {
	position: absolute;
	top: -35px;
	right: 0;
	font: bold 10px/21px "Roboto Cond";
	color: $white;
	text-transform: uppercase;
	font-stretch: condensed;
	letter-spacing: 1px;

	&:hover {
		cursor: pointer;
	}

	.close-ico {
		margin-left: 20px;
	}
}

// Tooltip

.tooltip{
  position: absolute;
  visibility: hidden;
  bottom:100%;
  left:50%;
  margin:0 0 -6px -115px;
  opacity:0;
  width:230px;
  letter-spacing:.3px;
  color: #808080;
  font-size:11px;
  line-height:18px;
  z-index:1;
  transition: all .3s ease;
}

.tooltip-inner{
	background-color: #fff;
	box-shadow: 0 1px 9px 0 rgba(0, 0, 0, 0.05), 0 1px 7px 0 rgba(0, 0, 0, 0.16);
	padding:15px 20px;
}

[data-tooltip-parent]:hover .tooltip{
	opacity:1;
	visibility: visible;
}

.day--selectable:hover .tooltip{
  display: none;
}

label[data-tooltip-parent] {
	position: relative;
}
