.top_box__shadow-bt.bg-comp {
	background-image: url('../images/bg-main.jpg');
	background-size: cover;
}

.bg-comp {
	h1 {
		margin: 0 0 50px 0;
	}
}

.comp-info {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;

	h3 {
		align-self: center;
		max-width: 565px;
		width: 100%;
		margin-bottom: 70px;
		font: bold 30px/35px "Proxima Nova";
		color: $blue;
	}

	.comp-info_box {
		display: flex;
		margin-bottom: 70px;
	}

	img {
		max-width: 378px;
		max-height: 320px;
		height: 100%;
		object-fit: cover;
	}

	.comp-info_box__thumb {
		align-self: center;
		max-width: 384px;
		margin-left: 9%;
	}

	.comp-info__main {
		align-self: center;
		max-width: 635px;
		margin-left: 73px;
		margin-bottom: 150px;
	}

}

.comp_team {
	max-width: 955px;
	margin: 0 auto 260px;

	h3 {
		margin-left: 6%;
		margin-bottom: 70px;
	}

	.comp__ctrl {
		width: 90px;
		margin: 70px auto;
	}
}

.team_member__container {
	display: flex;

	.member_img {
		img {
			max-width: 117px;
			max-height: 117px;
			height: 100vh;
			object-fit: cover;
		}
	}

	.member_box {
		margin-left: 6%;
		margin-top: 7px;
	}

	.member_head {
		margin-bottom: 15px;

		span {
			font: normal 11px/11px "Roboto";
			color: $gray;
			letter-spacing: .3px;
		}
	}

	.member_info {
		max-width: 250px;
	}

	.comp__mobile-ctrl {
		display: none;
	}

}

.comp_activity {
	max-width: 850px;
	margin: 0 auto 100px;

	h3 {
		max-width: 690px;
		margin-bottom: 70px;
	}
}

.comp_activity__block {
	display: flex;
	margin-bottom: 60px;

	.activity_box {
		padding-bottom: 25px;
		border-bottom: 2px solid $light-grey;
	}

	&:last-child {
		.activity_box {
			border-bottom: 0;
		}
	}

	.activity_ico {
		width: 63px;
		height: 63px;
		margin: 40px 70px 0 35px;
		text-align: right;
	}

	.activity_head {
		margin-bottom: 15px;

		h4 {
			font: bold 15px/22px "Roboto Cond";
			color: $text;
			text-transform: uppercase;
			letter-spacing: .5px;
		}
	}

	.activity_body {
		max-width: 587px;
		margin-bottom: 40px;
	}
}

.activity_details {
	.btn-more {
		position: relative;
	}
}
