.page404-container, .page404-title, .old-browser-container {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.page404-container, .old-browser-container {
  position: relative;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 auto;
  max-width: 930px;
  width: 100%;
  padding: 0 25px;
  padding-bottom: 70px;
  min-height: 400px;
}
.page404-container {
	height: calc(100vh - 221px);
}

.old-browser-container {
	height: calc(100vh - 295px);
}

.page404-container .logo-wrap, .old-browser-container .logo-wrap {
  position: absolute;
  top: -40px;
  left: 25px;
}

.page404-title {
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 40px;
  padding-top: 35px;
  max-width: 796px;
  width: 100%;
  height: 297px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI3OTciIGhlaWdodD0iMjk4IiB2aWV3Qm94PSIwIDAgNzk3IDI5OCI+ICAgIDxwYXRoIGZpbGw9IiMyNkFGQUYiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTI0OC4zNjQgMTgyLjc4N0gyMTMuOTZWNi41MTJoLTg2LjY1MUwuMzA1IDE3NS45OTJ2NjIuODY0SDE0OC4xMlYyOTEuMWg2NS44Mzh2LTUyLjI0NWgzNC40MDV2LTU2LjA2OXptLTE3Ny4xMjQgMEwxNDguMTIgNzQuMDV2MTA4LjczOEg3MS4yNHpNMzA4LjY4IDQxLjM0MmMtMjIuOTM3IDI3LjE4NS0zNC40MDUgNjIuODY1LTM0LjQwNSAxMDcuNDY0IDAgNDQuNiAxMS40NjggODAuMjggMzQuNDA1IDEwNy44OSAyMi45MzcgMjcuMTg0IDUzLjA5NSA0MC43NzYgOTAuODk5IDQwLjc3NnM2Ny45NjItMTMuNTkyIDkwLjg5OS00MC43NzdjMjMuMzYxLTI3LjYwOSAzNC44My02My4yODkgMzQuODMtMTA3Ljg4OSAwLTQ0LjYtMTEuNDY5LTgwLjI4LTM0LjgzLTEwNy40NjRDNDY3LjU0IDEzLjczMiA0MzcuMzgzLjE0IDM5OS41NzguMTRjLTM3LjgwMyAwLTY3Ljk2IDEzLjU5My05MC44OTggNDEuMjAyem01MC45NzEgMTcxLjE3OGMtOS43NjktMTUuNzE2LTE0Ljg2Ni0zNi45NTQtMTQuODY2LTYzLjcxNCAwLTI2Ljc2IDUuMDk3LTQ3LjU3MyAxNC44NjYtNjMuMjg5IDkuNzctMTUuNzE2IDIzLjM2Mi0yMy43ODYgMzkuOTI4LTIzLjc4NnMzMC4xNTggOC4wNyAzOS45MjcgMjMuNzg2YzEwLjE5NSAxNS43MTYgMTUuMjkyIDM2LjUzIDE1LjI5MiA2My4yOSAwIDI2Ljc2LTUuMDk3IDQ3Ljk5Ny0xNS4yOTIgNjMuNzEzLTkuNzcgMTUuNzE3LTIzLjM2MSAyMy4zNjItMzkuOTI3IDIzLjM2MnMtMzAuMTU4LTcuNjQ1LTM5LjkyOC0yMy4zNjJ6bTQzNi42NTQtMjkuNzMzaC0zNC40MDZWNi41MTJoLTg2LjY1bC0xMjcuMDA0IDE2OS40OHY2Mi44NjRINjk2LjA2VjI5MS4xSDc2MS45di01Mi4yNDVoMzQuNDA2di01Ni4wNjl6bS0xNzcuMTI1IDBMNjk2LjA2IDc0LjA1djEwOC43MzhINjE5LjE4eiIgb3BhY2l0eT0iLjA1Ii8+PC9zdmc+);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  h1 {
	margin-bottom: 50px;
    max-width: 380px;
    text-align: center;
    font-size: 40px;
    line-height: 40px;
    font: normal 45px/45px "Proxima Nova";
  	color: #0b6565;
  }
}

.page404-content {
  width: 100%;
  max-width: 300px;
  font-size: 14px;
  line-height: 22px;
}

.page404-content__title {
  margin-bottom: 20px;
  text-align: center;
}

.page404-menu {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 30px;
}

.btn-wrap {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 55px;
}

.old-browser-page {
	padding-top: 30px;
	.site-logo {
		display: block;
		margin: 0 auto;
		width: 73px;
	}
}

.old-browser__title {
  margin-bottom: 50px;
  width: 100%;
  max-width: 300px;
  text-align: center;
  font: normal 14px/22px "Roboto";
  color: #424242;
  letter-spacing: .2px;
}

.browser-list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 624px;
  width: 100%;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: center;
}

.browser-list__link {
  display: block;
  width: 40px;
  text-decoration: none;
  svg g, svg path {
    fill: grey;
  }
}

.browser-list__link:hover {
  svg g, svg path {
    fill:#04888C;
  }
  .browser-list__link-title {
	color: #04888C;
	}	
}

.browser-list__link-title {
	font-weight: bold;
	text-transform: uppercase;
	color: grey;
	font-size: 10px;
}
.parked-page-container {
  padding-top: 110px;
  h1 {
    margin-bottom: 19px;
    max-width: 320px;
    font: bold 15px/22px "Roboto Cond";
    color: $text;
    text-transform: uppercase;
    letter-spacing: .45px;
  }
  .page404-title {
    padding-top: 120px;
    background: url("../images/parked-page-bg.png") center no-repeat;
  }
  .btn-bordered {
    background: transparent;
  }
}