@media all and (min-width: 1070px) {
	.comp-info_box {
		justify-content: center;
	}
}


@media all and (max-width: 880px) {

	.comp-info {
		h3 {
			max-width: 458px;
		}

		.comp-info_box {
			height: 245px;
		}

		.comp-info_box__image {
			img {
				max-width: 290px;
			}
		}

		.comp-info_box__thumb {
			max-width: 340px;
		}

		.comp-info__main {
			margin: 0 auto 150px;
		}
	}

	.comp_team {
		position: relative;
		margin: 0 auto 150px;

		.team_member__container {
			margin-left: 6%;
		}

		.comp__ctrl {
			position: absolute;
			top: 65%;
			right: 15%;
			margin: 0;
			z-index: 2;
		}
	}

	.comp_activity {
		h3 {
			margin-left: 6%;
		}
	}

	.comp_activity__block {
		padding: 0 15px;

		.activity_body {
			max-width: 500px;
		}
	}
}

@media all and (max-width: 650px) {

	.comp-info {
		margin-bottom: 150px;

		.comp-info_box {
			flex-direction: column;
			height: auto;
			margin-bottom: 25px;
			img {
				max-width: 300px;
				margin-bottom: 45px;
			}
		}

		.comp-info_box__thumb {
			max-width: 635px;
			margin: 0 15px;
		}

		.comp-info__main {
			margin: 0 15px;
		}
	}

	.comp_team {
		margin-bottom: 110px;

		.comp__ctrl {
			top: 42%;
		}
	}

	.team_member__container {
		flex-direction: column;

		.member_box {
			margin-left: 0;
			margin-top: 35px;
		}
	}
}
