@media all and (max-width: 650px) {
	.connect-popup,
	.subscribe-popup {
		min-width: 390px;
		padding: 0;
	}
}

@media all and (max-width: 425px) {

	.connect-popup,
	.subscribe-popup {
		min-width: 320px;
	}

	.connect-logo {
		display: block;
		align-self: baseline;
		margin: 10px 0 35px 15px;
		img {
			height: 39px;
		}
	}

	.close-popup {
		top: 0;
		margin-top: 10px;
		padding: 10px 16px 10px 12px;
		background: $burger-menu;
	}

	.connect-error-box {
		top: 210px;
		width: 100%;
		text-align: center;
	}
}
