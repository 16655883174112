@media all and (max-width: 800px) {

	.contact_map_box {
		margin-bottom: 225px;
	}

	.contact_map__info {
		transform: translate(62%, 56%);
	}

	.map__info_box {
		.info_contact {
			display: none;
		}
	}

	.contact_acc__body {
		p {
			max-width: 515px;
		}
	}

}

@media all and (max-width: 650px) {

	.contact_head {
		padding: 50px;
		h1 {
			display: none;
		}
	}

	.contact_map {
		width: 100%;
		height: 315px;
	}

	.contact_map_box {
		margin-bottom: 280px;
	}

	.contact_map__info {
		width: 302px;
		transform: translate(6%, 58%)
	}

	.map__info_box {
		padding-left: 15%;

		.info_contact {
			display: block;
			margin-bottom: 26px;
			color: $blue;
			font: bold 24px/30px 'Proxima Nova', Arial;
			letter-spacing: -.1px;
		}
	}

	.contact_form {
		.form-error-box {
		left: 0;
		bottom: -160px;
		width: 100%;
		height: 155px;
		}
	}

}
