/* Catamaran list */
.top_box__shadow-bt.bg-cat {
	background-image: url('../images/bg-cat.jpg');
	background-size: cover;
}

.bg-cat {
	.top_cat_info {
		width: 65%;
	}
}

.cat_list {
	max-width: 1024px;
	padding-left: 50px;
	margin: 0 auto;
}

.cat_list__item {
	display: flex;
	position: relative;
	margin-bottom: 65px;

	.img {
		width: 458px;
		height: 447px;
		background-position: 50%;
		background-size: cover;
	}
}

.cat_list__item .info {
	position: absolute;
	top: 120px;
	right: 25px;
	width: 550px;
	height: 225px;
	padding: 0 0 0 130px;
	background-color: $light-grey2;
}

.cat_list__item .numb {
	position: absolute;
	top: 50%;
	left: 40px;
	width: 40px;
	margin: 3px 0 0;
	transform: translate(0, -50%);
	color: $blue;
	font: normal 20px/35px "Proxima Nova", Arial;
	text-align: center;
}

.cat_list__item .info__in {
	position: relative;
	top: 50%;
	padding: 0 0 10px;
	transform: translate(0,-50%);
}

.cat_list__item .info p {
	margin: 0 0 10px;
	color: $gray;
	font: normal 11px/27px "Roboto", Arial;
}

.cat_list__item .info h3 {
	margin: 0 0 5px;
	color: $blue;
	font: bold 30px/35px "Proxima Nova", Arial;
}

.cat_list__item .info h4 {
	color: $text;
	font: bold 15px/18px "Roboto Cond", Arial;
	letter-spacing: .5px;
	text-transform: uppercase;
}

.cat_list__item .info .btn-bordered {
	position: absolute;
	bottom: -20px;
	left: 130px;
}

.cat_list__more {
	text-align: center;
	margin-bottom: 125px;

	.arrow {
		right: 0;
		z-index: -1;
	}
}

.cat_list__more {
	a {
		position: relative;
		color: $blue;
		font: bold 25px/35px "Proxima Nova", Arial;
		text-decoration: none;
	}

	.arrow {
		top: -3px;
		width: 15px;
	}
}
