@charset "UTF-8";
/* Typography */
.header-lead {
  font: bold 45px/47px "Proxima Nova";
  color: #0b6565;
  letter-spacing: -.1px;
}

.header-contrast {
  font: bold 30px/35px "Proxima Nova";
  color: #0b6565;
}

.header-text {
  font: bold 18px/24px "Proxima Nova";
  color: #424242;
}

.link-text-big {
  font: bold 15px/35px "Roboto Cond";
  color: #424242;
  text-transform: uppercase;
  letter-spacing: .45px;
}

.link-text-small {
  font: bold 10px/21px "Roboto Cond";
  color: #424242;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.text {
  font: normal 14px/22px "Roboto";
  color: #424242;
  letter-spacing: .2px;
}

.text-footer {
  font: bold 11px/18px "Roboto";
  color: #424242;
  letter-spacing: .33px;
}

.text-footer-small {
  font: bold 9px/27px "Roboto";
  color: #808080;
  letter-spacing: .27px;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

img, fieldset, a img {
  border: 0;
}

input, textarea {
  -webkit-appearance: none;
}

textarea {
  overflow: auto;
}

input, button {
  margin: 0;
  padding: 0;
  border: 0;
}

input[type="submit"],
button {
  cursor: pointer;
}

div, input, textarea, select, button, h1, h2, h3, h4, h5, h6, a, span, a:focus {
  outline: none;
}

ul, ol {
  list-style-type: none;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  position: relative;
  height: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.cf::after {
  display: block;
  height: 0;
  clear: both;
  content: ' ';
  visibility: hidden;
}

* html .cf {
  zoom: 1;
}

*:first-child + html .cf {
  zoom: 1;
}

body {
  height: 100%;
  position: relative;
  -webkit-overflow-scrolling: touch;
}

body.overflow .body-wrap {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.body-wrap {
  opacity: 0;
  transition: opacity 1000ms;
}

.bodymovin {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  z-index: 100;
}

.hidden {
  opacity: 0;
}

.visible {
  opacity: 1;
}

.overflow {
  overflow: hidden;
}

@font-face {
  font-family: 'Roboto Cond';
  src: url("../fonts/Roboto-BoldCondensed.eot");
  src: url("../fonts/Roboto-BoldCondensed.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-BoldCondensed.woff") format("woff"), url("../fonts/Roboto-BoldCondensed.ttf") format("truetype"), url("../fonts/Roboto-BoldCondensed.svg#Roboto-BoldCondensed") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/Roboto-Regular.eot");
  src: url("../fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Regular.woff") format("woff"), url("../fonts/Roboto-Regular.ttf") format("truetype"), url("../fonts/Roboto-Regular.svg#Roboto-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Cond';
  src: url("../fonts/Roboto-Condensed.eot");
  src: url("../fonts/Roboto-Condensed.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Condensed.woff") format("woff"), url("../fonts/Roboto-Condensed.ttf") format("truetype"), url("../fonts/Roboto-Condensed.svg#Roboto-Condensed") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url("../fonts/ProximaNova-Light.eot");
  src: url("../fonts/ProximaNova-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNova-Light.woff") format("woff"), url("../fonts/ProximaNova-Light.ttf") format("truetype"), url("../fonts/ProximaNova-Light.svg#ProximaNova-Light") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url("../fonts/ProximaNova-Semibold.eot");
  src: url("../fonts/ProximaNova-Semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNova-Semibold.woff") format("woff"), url("../fonts/ProximaNova-Semibold.ttf") format("truetype"), url("../fonts/ProximaNova-Semibold.svg#ProximaNova-Semibold") format("svg");
  font-weight: bold;
  font-style: normal;
}

.is-open {
  display: block !important;
}

.top_box__video {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.top_box__video video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100%;
  transform: translate3d(-50%, -50%, 0);
}

.video-disabled {
  background-size: cover;
}

.video-disabled video {
  display: none;
}

/* Buttons */
.btn-bordered,
.btn-green {
  position: relative;
  overflow: hidden;
}

.btn-bordered .btn-name,
.btn-green .btn-name {
  position: absolute;
  top: 0;
  right: 45px;
  left: 10px;
  z-index: 1;
}

.btn-bordered .arr-r,
.btn-green .arr-r {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 45px;
  z-index: 1;
}

.btn-bordered .arrow,
.btn-green .arrow {
  right: 70px;
  z-index: -1;
}

.btn-bordered .btn-bg,
.btn-green .btn-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: #04888c;
  z-index: 0;
}

.btn-green .btn-name {
  right: 30px;
}

.btn-green .arr-r {
  right: 20px;
}

.btn-bordered {
  display: inline-block;
  width: 160px;
  height: 40px;
  transition: all 200ms ease-out;
  border: 1px solid #0b6565;
  outline: none;
  background-color: #fff;
  color: #424242;
  font: bold 10px/40px "Roboto Cond", Arial;
  letter-spacing: 1px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase;
}

.btn-bordered:hover {
  color: #fff;
}

.btn-bordered .arr-r::after {
  display: block;
  position: absolute;
  top: 50%;
  right: -6px;
  width: 0;
  height: 0;
  transform: translate(0, -50%) scale(1.5, 1.1);
  border: 3px solid transparent;
  border-left-color: #0b6565;
  content: ' ';
}

.btn-bordered .arr-r::before {
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  width: 8px;
  height: 1.3px;
  transform: translate(0, -50%);
  transition: width 250ms ease-out;
  background-color: #0b6565;
  content: ' ';
}

.btn-green {
  display: inline-block;
  width: 160px;
  height: 40px;
  outline: none;
  background-color: #26afaf;
  color: #fff;
  font: bold 10px/41px "Roboto Cond", Arial;
  letter-spacing: 1px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
}

.btn-green .arr-r::after {
  display: block;
  position: absolute;
  top: 50%;
  right: -6px;
  width: 0;
  height: 0;
  transform: translate(0, -50%) scale(1.5, 1.1);
  border: 3px solid transparent;
  border-left-color: #0b6565;
  content: ' ';
}

.btn-green .arr-r::before {
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  width: 8px;
  height: 1.3px;
  transform: translate(0, -50%);
  transition: width 250ms ease-out;
  background-color: #0b6565;
  content: ' ';
}

.btn-more {
  display: inline-block;
  transition: all 200ms ease-out;
  outline: none;
  color: #424242;
  font: bold 10px/21px "Roboto Cond", Arial;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  transition: color ease-in-out 200ms;
}

.btn-more:hover {
  color: #04888c;
}

.comments_box__ctrl ul,
.comp__ctrl ul,
.edge_box__ctrl ul {
  margin: 0;
  padding: 0 0 0 1px;
  list-style: none;
}

.comments_box__ctrl ul li,
.comp__ctrl ul li,
.edge_box__ctrl ul li {
  position: relative;
  width: 44px;
  height: 40px;
  margin: 0 0 0 -1px;
  float: left;
  border: 1px solid #0b6565;
  cursor: pointer;
}

.comments_box__ctrl ul li:hover,
.comp__ctrl ul li:hover,
.edge_box__ctrl ul li:hover {
  z-index: 2;
}

.comments_box__ctrl .arrow,
.comp__ctrl .arrow,
.edge_box__ctrl .arrow {
  position: absolute;
  top: 8px;
  left: 16px;
  width: 24%;
  z-index: 1;
}

.comments_box__ctrl .btn-bg,
.comp__ctrl .btn-bg,
.edge_box__ctrl .btn-bg {
  position: absolute;
  top: 0;
  width: 0;
  height: 100%;
  background: #04888c;
  z-index: 0;
}

.comments_box__ctrl .next,
.comp__ctrl .next,
.edge_box__ctrl .next {
  left: 0;
}

.comments_box__ctrl .prev .btn-bg,
.comp__ctrl .prev .btn-bg,
.edge_box__ctrl .prev .btn-bg {
  right: 0;
}

.comments_box__ctrl svg,
.comp__ctrl svg,
.edge_box__ctrl svg {
  overflow: visible;
}

.close-popup {
  position: absolute;
  top: -35px;
  right: 0;
  font: bold 10px/21px "Roboto Cond";
  color: #fff;
  text-transform: uppercase;
  font-stretch: condensed;
  letter-spacing: 1px;
}

.close-popup:hover {
  cursor: pointer;
}

.close-popup .close-ico {
  margin-left: 20px;
}

.tooltip {
  position: absolute;
  visibility: hidden;
  bottom: 100%;
  left: 50%;
  margin: 0 0 -6px -115px;
  opacity: 0;
  width: 230px;
  letter-spacing: .3px;
  color: #808080;
  font-size: 11px;
  line-height: 18px;
  z-index: 1;
  transition: all .3s ease;
}

.tooltip-inner {
  background-color: #fff;
  box-shadow: 0 1px 9px 0 rgba(0, 0, 0, 0.05), 0 1px 7px 0 rgba(0, 0, 0, 0.16);
  padding: 15px 20px;
}

[data-tooltip-parent]:hover .tooltip {
  opacity: 1;
  visibility: visible;
}

.day--selectable:hover .tooltip {
  display: none;
}

label[data-tooltip-parent] {
  position: relative;
}

/* Bg */
.bg-main {
  background: url("../images/bg-main.jpg") center bottom no-repeat;
  background-size: cover;
}

/* Header */
.top {
  position: absolute;
  top: 30px;
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: 6;
}

.top__logo {
  margin-left: 5%;
  float: left;
}

.top__logo img {
  display: block;
  width: 100%;
  max-width: 110px;
  height: auto;
}

.top__lang ul {
  margin: 0;
  list-style: none;
}

.top__lang ul li {
  display: inline-block;
  margin-right: 15px;
  vertical-align: middle;
}

.top__lang ul li.active a {
  color: #0b6565;
}

.top__lang ul li a {
  color: #424242;
  font: normal 13px/27px "Roboto";
  letter-spacing: .2px;
  text-decoration: none;
}

.top__lang ul li a:hover {
  color: #04888c;
}

.top__nav h5 {
  margin: 0 0 25px;
  color: #808080;
  font: normal 9px/100% "Roboto", Arial;
}

.top__nav ul {
  margin: 0;
  list-style: none;
}

.top__nav ul li {
  margin: 0 0 2px;
}

.top__nav ul li.is-secondary a {
  color: #fff;
}

.top__nav ul li.is-secondary.active a {
  color: #26afaf;
}

.top__nav ul li:last-child {
  margin: 0 0 10px;
}

.top__nav ul li a {
  color: #424242;
  font: bold 10px/21px "Roboto Cond";
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
}

.top__nav ul li.active a {
  color: #0b6565;
}

.top__nav ul li a:hover {
  color: #04888c;
}

.top__call {
  width: 110px;
}

.navigation .news-column .news-line {
  opacity: 0;
}

/* Footer */
.footer {
  max-width: 930px;
  margin: 0 auto;
  padding-bottom: 30px;
}

.footer__contacts {
  margin: 0;
  float: left;
}

.footer__contacts ul {
  margin: 0;
  list-style: none;
}

.footer__contacts ul li {
  display: inline-block;
  margin: 0 32px 0 0;
  color: #424242;
  font: normal 14px/100% "Roboto", Arial;
  vertical-align: middle;
}

.footer__contacts ul li a {
  color: inherit;
  text-decoration: none;
}

.footer__contacts ul li a:hover {
  color: #04888c;
  text-decoration: underline;
}

.footer__contacts-in a {
  display: block;
  width: 12px;
  height: 12px;
  background: url("../images/ico-instagram-g.png");
  background-size: 12px 12px;
  text-indent: -9999px;
}

.footer__contacts .footer__contacts-fb {
  margin-left: -5px;
}

.footer__contacts-fb a {
  display: block;
  width: 12px;
  height: 12px;
  background: url("../images/ico-fb-g.png") center 0 no-repeat;
  background-size: 6px 12px;
  text-indent: -9999px;
}

.footer__contacts-in a:hover,
.footer__contacts-fb a:hover {
  opacity: .8;
}

.footer__info {
  float: right;
}

.footer__info p {
  display: inline-block;
  width: 225px;
  margin: 0 40px 0 0;
  color: #424242;
  font: normal 14px/22px "Roboto", Arial;
  letter-spacing: .2px;
  text-align: right;
  vertical-align: middle;
}

.footer__info .btn {
  vertical-align: middle;
}

.footer__pay {
  float: left;
}

.footer__pay ul {
  margin: 0;
  list-style: none;
}

.footer__pay ul li {
  display: inline-block;
  margin: 0 20px 0 0;
  text-indent: -9999px;
  vertical-align: middle;
}

.footer__pay ul li:last-child {
  margin-right: 0;
}

.footer__pay ul li.pp {
  width: 54px;
  height: 14px;
  background: url("../images/logo-paypal.png");
  background-size: 54px 14px;
}

.footer__pay ul li.vi {
  width: 31px;
  height: 11px;
  background: url("../images/logo-visa.png");
  background-size: 31px 11px;
}

.footer__pay ul li.mc {
  width: 82px;
  height: 16px;
  background: url("../images/logo-mastercard.png");
  background-size: 82px 16px;
}

.footer__copy {
  float: right;
}

.footer__copy p {
  margin: 7px 0 0;
  color: #808080;
  font: normal 9px/100% "Roboto", Arial;
  letter-spacing: .2px;
}

.footer__dev {
  text-align: center;
}

.footer__dev ul {
  list-style: none;
}

.footer__dev ul li {
  display: inline-block;
  margin: 0 9px;
  color: #808080;
  font: normal 9px/100% "Roboto", Arial;
  vertical-align: middle;
}

.footer__dev ul li a {
  display: inline-block;
  margin-left: 4px;
  color: inherit;
  text-decoration: none;
}

.footer__dev ul li a:hover {
  color: #04888c;
  text-decoration: none;
}

/* Top box */
.top_box {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 0 120px;
}

.top_box__shadow-top:before {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 30%;
  background: linear-gradient(to bottom, white 0%, rgba(255, 255, 255, 0) 100%);
  content: ' ';
  z-index: 1;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$whitefff', endColorstr='#00ffffff',GradientType=0);
  pointer-events: none;
}

.top_box__shadow-bt::after {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 100%);
  content: ' ';
  z-index: 1;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='$whitefff',GradientType=0);
  pointer-events: none;
}

.opacity-block {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  content: ' ';
  pointer-events: none;
}

.top_box__title {
  position: absolute;
  right: 0;
  bottom: 120px;
  left: 0;
  z-index: 2;
}

.top_box__title-txt {
  max-width: 560px;
  width: 100%;
  margin: 0 auto;
  color: #0b6565;
  font: bold 45px/47px "Proxima Nova", Arial;
  letter-spacing: -.3px;
}

.top_cat_info {
  position: absolute;
  right: 0;
  bottom: 20%;
  left: 0;
  width: 65%;
  max-width: 680px;
  margin: 0 auto;
  z-index: 2;
}

.top_cat_info h5 {
  margin: 0 0 13px;
  color: #808080;
  font: normal 11px/100% "Roboto", Arial;
}

.top_cat_info h1 {
  margin: 0 0 5px;
  color: #0b6565;
  font: bold 45px/47px "Proxima Nova", Arial;
}

.top_cat_info h4 {
  margin: 0 0 27px;
  color: #424242;
  font: bold 15px/100% "Roboto Cond", Arial;
  letter-spacing: .4px;
  text-transform: uppercase;
}

/* Info box */
.info_box {
  display: flex;
  justify-content: center;
  margin: 0 10px 165px;
}

.info_box__left {
  align-self: stretch;
  margin: 0 58px 0 0;
}

.info_box__left p {
  width: 70px;
  margin: 8px 0 0;
  color: #808080;
  font: normal 11px/18px "Roboto", Arial;
}

.info_box__body {
  max-width: 600px;
}

.info_box__body h3 {
  margin: 0 0 35px;
  color: #0b6565;
  font: bold 30px/35px "Proxima Nova", Arial;
}

.info_box__body p {
  color: #424242;
  font: normal 14px/22px "Proxima Nova", Arial;
}

/* Edge box */
.edge_box {
  position: relative;
  margin: 0 0 35px;
  padding: 60px 0 100px;
  background: url("../images/bg-edge.jpg") no-repeat;
  background-size: cover;
}

.edge_box__items {
  display: flex;
  flex-wrap: wrap;
}

.edge_box__in {
  max-width: 845px;
  margin: 0 auto;
  overflow: hidden;
}

.edge_box__title {
  position: relative;
  margin: 0 0 50px 30px;
  z-index: 2;
}

.edge_box__title h3 {
  color: #0b6565;
  font: bold 45px/47px "Proxima Nova", Arial;
  letter-spacing: -.1px;
}

.edge_box__item {
  position: relative;
  max-width: 380px;
  min-width: 380px;
  min-height: 270px;
  margin: 20px;
  padding: 63px 60px 35px 85px;
  float: left;
  background-color: #fff;
  z-index: 5;
}

.edge_box__item .numb {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  background-color: #0b6565;
  color: #fff;
  font: normal 20px/50px "Proxima Nova", Arial;
  text-align: center;
}

.edge_box__item h4 {
  margin: 0 0 15px;
  color: #424242;
  font: bold 15px/22px "Roboto Cond", Arial;
  letter-spacing: .5px;
  text-transform: uppercase;
}

.edge_box__item p {
  color: #424242;
  font: normal 14px/22px "Roboto", Arial;
  letter-spacing: .2px;
}

/* Routes box */
.routes_box {
  margin-bottom: 125px;
}

.routes_box.routes_box--tabbed {
  margin-bottom: 377px;
}

.routes_box__in {
  max-width: 845px;
  margin: 0 auto 45px;
}

.routes_box__title {
  margin: 0 30px 30px;
}

.routes_box__title h3 {
  max-width: 570px;
  margin: 0 0 30px;
  color: #0b6565;
  font: bold 45px/47px "Proxima Nova", Arial;
  letter-spacing: -.1px;
}

.routes_box__title p {
  max-width: 570px;
  color: #424242;
  font: normal 14px/22px "Roboto", Arial;
  letter-spacing: .2px;
}

.routes_box__legend {
  margin: 0 auto 35px;
}

.routes_box__legend ul {
  margin: 0 0 0 7px;
  list-style: none;
}

.routes_box__legend ul li {
  display: inline-block;
  margin: 0 55px 0 0;
  padding: 0 0 0 35px;
  color: #808080;
  font: normal 11px/27px "Roboto", Arial;
  vertical-align: middle;
}

.routes_box__legend ul li.ico1 {
  background: url("../images/ico-map_legend1.png") 0 center no-repeat;
  background-size: 17px 17px;
}

.routes_box__legend ul li.ico2 {
  background: url("../images/ico-map_legend2.png") 0 center no-repeat;
  background-size: 15px 15px;
}

.routes_box__body {
  position: relative;
  height: 345px;
  width: 100%;
  margin: 0 0 25px;
}

.routes_box__map,
.blog-article-map {
  position: relative;
  width: 85%;
  height: 345px;
  float: left;
  transition: all 350ms ease-out;
  z-index: 1;
}

.routes_box__body--active .routes_box__map,
.routes_box__body--active .blog-article-map {
  width: 50%;
}

.routes_box__body--active.routes_box__body--active-mobile .routes_box__map {
  width: 100%;
}

.routes_box__info {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 350px;
  padding: 25px 0 0 50px;
  transform: translate3d(-200%, -50%, 0);
  transition: all 350ms ease-out;
  opacity: 1;
  overflow: hidden;
}

.routes_box__info--active {
  transform: translate3d(0, -50%, 0);
  opacity: 1;
}

.routes_box__info--active.routes_box__info--active-mobile {
  max-width: 605px;
  width: 100%;
  padding: 0;
  transform: translate(-47%, 158%);
}

.routes_box__info h4 {
  margin: 0 0 25px;
  color: #424242;
  font: bold 15px/22px "Roboto Cond", Arial;
  text-transform: uppercase;
}

.routes_box__info p {
  margin: 0 0 30px;
  color: #424242;
  font: normal 14px/22px "Roboto", Arial;
  letter-spacing: .2px;
}

.routes_box__info-btns {
  text-align: left;
}

.routes_box__info-btns a,
.routes_box__info-btns button {
  display: inline-block;
  padding: 0 25px 0 0;
  color: #424242;
  font: bold 10px/21px "Roboto", Arial;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
}

.routes_box__info-btns a:hover,
.routes_box__info-btns button:hover {
  color: #04888c;
  text-decoration: none;
}

.routes_box__info-more {
  background: url("../images/ico-arr-r-g.png") right center no-repeat;
  background-size: 9px 6px;
}

.routes_box__info-close {
  position: relative;
  float: right;
  background-color: transparent;
}

.routes_box__info-close::after {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 1px;
  width: 9px;
  height: 7px;
  margin: auto;
  transition: all 150ms ease-out;
  background: url("../images/ico-close-g.png") no-repeat;
  background-size: 9px 7px;
  content: ' ';
}

.routes_box__info-close:hover::after {
  transform: scale(1.2);
}

/* News */
.news_box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 800px;
  margin: 0 auto 135px;
}

.news_box .news__header {
  width: 100%;
  margin-top: 100px;
  margin-bottom: 50px;
}

.news_box .news__header h3 {
  font: bold 45px/47px "Proxima Nova", Arial;
  letter-spacing: -.1px;
  color: #0b6565;
}

.news__column {
  width: 100%;
  max-width: 385px;
}

.news__column .news__link {
  display: block;
  width: 100%;
  height: 244px;
  text-decoration: none;
}

.news__column .news__image {
  height: 244px;
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 37px;
}

.news__column .news__image a {
  display: block;
  width: 100%;
  height: 100%;
}

.news__column .news__image img {
  vertical-align: top;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.news__column .news__head {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.news__column .news__head .news__date {
  min-width: 80px;
  margin-right: 20px;
  font: normal 11px/27px "Roboto", Arial;
  color: #808080;
}

.news__column .news__head .news__title h4 {
  margin-top: 2px;
  font: bold 15px/22px "Roboto Cond", Arial;
  color: #424242;
  letter-spacing: .5px;
  margin-bottom: 25px;
}

.news__column .news__head .news__title a {
  text-decoration: none;
}

.news__column .news__head .news__content {
  max-width: 270px;
  color: #424242;
  font: normal 14px/22px "Roboto", Arial;
  letter-spacing: .2px;
}

.news__body {
  width: 100%;
}

/* Comments */
.comments_box {
  position: relative;
  margin-bottom: 130px;
  padding-top: 77px;
  padding-bottom: 150px;
  background: #f6f6f6;
}

.comments_box__header {
  width: 100%;
  max-width: 800px;
  margin: 0 auto 53px;
}

.comments_box__header h3 {
  font: bold 45px/47px "Proxima Nova", Arial;
  letter-spacing: -.1px;
  color: #0b6565;
}

.comments_box__slider {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.comments_box__count {
  display: block;
  color: #808080;
  font: normal 9px/27px "Roboto", Arial;
  text-align: center;
}

.comments_box__count .index {
  display: inline-block;
  color: #0b6565;
  font: 300 20px/35px "Proxima Nova", Arial;
  vertical-align: middle;
}

.comments_box__item {
  position: relative;
  float: right;
}

.comments_box__item .comments_box__image {
  position: absolute;
  top: 45px;
  left: -168px;
  width: 200px;
  height: 200px;
  background-position: center;
  background-size: cover;
}

.comments_box__item .comments_box__body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 545px;
  height: 295px;
  padding: 45px 85px 45px 130px;
  background: #fff;
}

.comments_box__item .comments_box__body h5 {
  margin-bottom: 14px;
  color: #808080;
  font: normal 11px/27px "Roboto", Arial;
  letter-spacing: .3px;
}

.comments_box__item .comments_box__body p {
  margin-bottom: 35px;
  color: #424242;
  font: normal 14px/22px "Roboto", Arial;
  letter-spacing: .2px;
}

.comments_box__item .comments_box__body .more-comments {
  align-self: flex-start;
  position: relative;
}

.comments_controls {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
}

.comments_controls .comments_box__ctrl {
  position: absolute;
  left: 32%;
  top: 30px;
  z-index: 1;
}

.comments_controls .comments_box__count {
  position: absolute;
  top: -34px;
  left: 11%;
}

.partners_box {
  max-width: 845px;
  width: 100%;
  margin: 0 auto 100px;
}

.partners_box__header {
  width: 100%;
  margin: 0 0 30px;
}

.partners_box__header h3 {
  font: bold 45px/47px "Proxima Nova", Arial;
  letter-spacing: -.1px;
  color: #0b6565;
}

.partners_box__items {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
}

.partners_box__items .partners_box__item {
  align-self: center;
  max-width: 100px;
  width: 100%;
  margin: 23px 50px;
}

.partners_box__items .partners_box__item img {
  max-width: 100px;
}

.navigation-column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  -webkit-overflow-scrolling: touch;
}

.navigation-column .top__lang {
  margin-right: 10px;
}

.navigation-column .top__nav {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}

.navigation-column .btn-green {
  width: 123px;
}

.navigation-column .btn-green .arr-r {
  margin: 0;
  padding: 0 20px 0 0;
}

.navigation-column .list-unstyled, .navigation-column h5 {
  margin-left: 15px;
}

.navigation-column .top__call {
  position: fixed;
  bottom: 35px;
}

.navigation-column .top__call .call {
  color: #424242;
  font: bold 10px/21px "Roboto Cond";
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
}

.navigation-column .top__call .call span {
  display: inline-block;
  width: 10px;
  height: 15px;
  margin: 0 20px 2px;
  vertical-align: middle;
}

.navigation-column .top__call .call span svg {
  overflow: visible;
}

.navigation-column .top__call .call:hover {
  color: #04888c;
}

.navigation-column.index-navigation-column {
  height: 89vh;
}

.news-column {
  display: none;
}

.edge_box__ctrl {
  display: none;
}

.footer__subscribe {
  padding-top: 52px;
  padding-bottom: 100px;
}

.footer__subscribe .btn-more {
  position: relative;
}

.arrow {
  position: absolute;
  display: inline-block;
  top: 0;
  right: -20px;
  width: 11px;
}

.arrow svg {
  overflow: visible;
  backface-visibility: hidden;
}

.opacity-body {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #0b0b0b;
  opacity: 0;
  z-index: 0;
}

.section-booking .btn-bordered .arrow,
.section-booking .btn-green .arrow {
  top: 16px;
}

.section-booking .cat-more {
  position: relative;
}

.section-booking .cat-more .arrow {
  top: 11px;
}

/* Catamaran list */
.top_box__shadow-bt.bg-cat {
  background-image: url("../images/bg-cat.jpg");
  background-size: cover;
}

.bg-cat .top_cat_info {
  width: 65%;
}

.cat_list {
  max-width: 1024px;
  padding-left: 50px;
  margin: 0 auto;
}

.cat_list__item {
  display: flex;
  position: relative;
  margin-bottom: 65px;
}

.cat_list__item .img {
  width: 458px;
  height: 447px;
  background-position: 50%;
  background-size: cover;
}

.cat_list__item .info {
  position: absolute;
  top: 120px;
  right: 25px;
  width: 550px;
  height: 225px;
  padding: 0 0 0 130px;
  background-color: #f6f6f6;
}

.cat_list__item .numb {
  position: absolute;
  top: 50%;
  left: 40px;
  width: 40px;
  margin: 3px 0 0;
  transform: translate(0, -50%);
  color: #0b6565;
  font: normal 20px/35px "Proxima Nova", Arial;
  text-align: center;
}

.cat_list__item .info__in {
  position: relative;
  top: 50%;
  padding: 0 0 10px;
  transform: translate(0, -50%);
}

.cat_list__item .info p {
  margin: 0 0 10px;
  color: #808080;
  font: normal 11px/27px "Roboto", Arial;
}

.cat_list__item .info h3 {
  margin: 0 0 5px;
  color: #0b6565;
  font: bold 30px/35px "Proxima Nova", Arial;
}

.cat_list__item .info h4 {
  color: #424242;
  font: bold 15px/18px "Roboto Cond", Arial;
  letter-spacing: .5px;
  text-transform: uppercase;
}

.cat_list__item .info .btn-bordered {
  position: absolute;
  bottom: -20px;
  left: 130px;
}

.cat_list__more {
  text-align: center;
  margin-bottom: 125px;
}

.cat_list__more .arrow {
  right: 0;
  z-index: -1;
}

.cat_list__more a {
  position: relative;
  color: #0b6565;
  font: bold 25px/35px "Proxima Nova", Arial;
  text-decoration: none;
}

.cat_list__more .arrow {
  top: -3px;
  width: 15px;
}

@media all and (min-width: 881px) {
  .news-column {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    width: 50%;
    height: 100vh;
    background-color: #26afaf;
    padding-right: 125px;
    padding-left: 30px;
    transform: translateX(100%);
    transition: transform .4s ease-in-out;
  }
  .news-column .news-wrap {
    position: relative;
    align-self: center;
    padding: 40px;
  }
  .news-column .news-wrap .close-more-menu {
    top: 45px;
    left: 0;
  }
  .news-column .news-wrap .close-more-menu:before, .news-column .news-wrap .close-more-menu:after {
    background: #fff;
  }
  .news-column .news-line {
    padding: 35px 0;
    border-bottom: 1px solid #0b6565;
  }
  .news-column .news-line:last-child {
    border-bottom: 0;
  }
  .news-column .news-line a {
    color: #fff;
    font: bold 15px/22px "Roboto", Arial;
    letter-spacing: .5px;
    text-transform: uppercase;
    text-decoration: none;
  }
  .news-column--active {
    transform: translateX(0);
    transition: transform .4s ease-in-out;
  }
  .news-column--active .news-line {
    opacity: 1;
  }
  .news-column__heading {
    color: #fff;
  }
  .top__nav--active {
    z-index: 2;
  }
  .top__nav--active h5,
  .top__nav--active ul li a {
    color: #fff;
  }
  .top__nav--active .arr-r {
    right: 20px !important;
  }
  .mobile-menu {
    display: none;
  }
}

@media all and (max-width: 1070px) {
  .edge_box__in {
    margin: 0 6%;
  }
  .routes_box__title {
    margin: 0;
  }
  .news_box {
    margin: 0 8% 135px;
  }
  .comments_box__header {
    margin: 0 8% 53px;
  }
  .comments_box__slider,
  .comments_controls {
    margin: 0 8%;
  }
}

@media all and (max-width: 880px) {
  .top__logo {
    margin-left: 7%;
  }
  .top__logo img {
    max-width: 75px;
  }
  .navigation {
    position: fixed;
    top: -750px;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 7;
  }
  .navigation .navigation-column {
    display: block;
    position: relative;
    background-color: #fff;
    width: 100%;
    height: 480px;
    padding: 35px 30px;
  }
  .navigation .navigation-column .top__lang {
    opacity: 0;
  }
  .navigation .navigation-column .top__nav {
    position: relative;
    opacity: 0;
  }
  .navigation .navigation-column .top__nav li {
    margin-bottom: 0;
  }
  .navigation .navigation-column .top__nav a {
    font: bold 15px/35px "Roboto Cond";
    text-transform: uppercase;
    letter-spacing: 1.6px;
  }
  .navigation .navigation-column .top__call {
    position: absolute;
    bottom: 10px;
    margin-left: 15px;
    opacity: 0;
  }
  .navigation .navigation-column .top__call .call {
    font: bold 15px/35px "Roboto Cond";
    letter-spacing: .45px;
    vertical-align: top;
  }
  .navigation .navigation-column .btn-green {
    width: 173px;
    height: 45px;
    margin: 15px 0;
  }
  .navigation .navigation-column .btn-green .arr-r {
    color: #fff;
    font: bold 15px/48px "Roboto Cond";
    padding: 0 35px 0 0;
  }
  .navigation .navigation-column .btn-green .btn-name {
    margin-top: 6px;
  }
  .navigation .news-column {
    position: absolute;
    top: 0;
    right: -500px;
    display: flex;
    justify-content: center;
    width: 50%;
    height: 480px;
    background-color: #26afaf;
  }
  .navigation .news-column .close-more-menu, .navigation .news-column .news-column__heading {
    display: none;
  }
  .navigation .news-column .news-wrap {
    align-self: center;
    padding: 40px;
  }
  .navigation .news-column .news-line {
    padding: 35px 0;
    border-bottom: 1px solid #0b6565;
  }
  .navigation .news-column .news-line:last-child {
    border-bottom: 0;
  }
  .navigation .news-column .news-line a {
    color: #fff;
    font: bold 15px/22px "Roboto", Arial;
    letter-spacing: .5px;
    text-transform: uppercase;
    text-decoration: none;
  }
  .mobile-menu {
    display: block;
    position: fixed;
    top: 32px;
    right: 0;
    width: 48px;
    height: 36px;
    background-color: #26afaf;
    cursor: pointer;
    z-index: 7;
  }
  .btn-green .btn-name {
    right: 45px;
  }
  .top_box__video {
    display: none;
  }
  .top_box__shadow-bt {
    background-image: url("../images/bg-main.jpg");
    background-size: cover;
  }
  .info_box {
    margin-bottom: 100px;
    padding: 0 50px;
  }
  .info_box__left {
    margin-right: 90px;
  }
  .info_box__left p {
    width: 75px;
  }
  .info_box__body h3 {
    width: 400px;
  }
  .info_box__body p {
    width: 458px;
  }
  .cat_list {
    padding: 0;
  }
  .cat_list__items {
    margin-left: 15px;
  }
  .cat_list__item .img {
    width: 377px;
    height: 367px;
  }
  .cat_list__item .info {
    position: absolute;
    top: 0;
    left: 0;
    width: 427px;
    transform: translate(70%, 35%);
  }
  .edge_box__in {
    padding-left: 50px;
    margin: 0;
  }
  .edge_box__title {
    margin: 0 0 50px 0;
  }
  .edge_box__item {
    max-width: 300px;
    min-width: 300px;
    margin: 0 40px 40px 0;
    padding: 80px 30px 45px 45px;
  }
  .routes_box {
    margin-bottom: 68px;
  }
  .routes_box__title {
    margin: 0 50px 30px;
  }
  .routes_box__map {
    width: 100%;
  }
  .routes_box__legend ul {
    display: flex;
    margin-left: 90px;
  }
  .news_box {
    max-width: 635px;
  }
  .news__column {
    max-width: 296px;
    margin-bottom: 50px;
  }
  .news__column .news__image {
    margin-bottom: 18px;
    height: 190px;
  }
  .news__column .news__head {
    display: block;
  }
  .news__column .news__body {
    margin-top: 28px;
  }
  .comments_box__header,
  .partners_box__header {
    margin: 0 0 30px;
    padding-left: 60px;
  }
  .comments_box__item .comments_box__body {
    width: 510px;
  }
  .comments_box__slider {
    margin: 0;
  }
  .comments_controls {
    margin: 0;
  }
  .comments_controls .comments_box__ctrl {
    left: 34%;
  }
  .comments_controls .comments_box__count {
    left: 12%;
  }
  .partners_box__items {
    max-width: 600px;
  }
  .partners_box__items .partners_box__item {
    margin: 25px;
  }
  .footer {
    display: flex;
    flex-direction: column;
  }
  .footer__info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 60px 90px 65px 50px;
    background: #f6f6f6;
    text-align: center;
    vertical-align: middle;
  }
  .footer__info p {
    width: 100%;
    text-align: left;
    line-height: 40px;
  }
  .footer__info .btn-bordered {
    padding: 0 79px;
  }
  .footer__contacts {
    padding: 60px 90px 50px 50px;
  }
  .footer__contacts ul {
    display: flex;
    justify-content: space-around;
  }
  .footer__contacts .footer__contacts-phone {
    margin-right: 50px;
  }
  .footer__contacts .footer__contacts-email {
    flex-grow: 1;
  }
  .footer__contacts .footer__contacts-in {
    margin-right: 30px;
  }
  .footer__contacts .footer__contacts-fb {
    margin-right: 0;
  }
  .footer__subscribe {
    padding: 0 0 70px 50px;
  }
  .footer__credits {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 90px 0 50px;
  }
  .footer__credits .footer__pay {
    margin-bottom: 35px;
  }
  .footer__credits .footer__copy {
    order: 1;
    flex-grow: 1;
    width: 100%;
  }
  .footer__credits .footer__dev ul li:last-child {
    margin-right: 0;
  }
}

@media all and (max-width: 750px) {
  .cat_list__item {
    margin-bottom: 115px;
  }
  .cat_list__item .info {
    transform: translate(45%, 50%);
  }
  .edge_box__item {
    width: 280px;
    margin: 0 10px 10px 0;
  }
  .routes_box__info {
    width: 305px;
    padding: 20px 0 0 20px;
  }
  .comments_box__slider {
    max-width: 500px;
    margin: 0 auto;
  }
  .comments_box__item .comments_box__image {
    position: static;
    transform: translate(22%, 26%);
  }
  .comments_box__item .comments_box__body {
    padding: 85px 45px 45px;
  }
  .comments_controls {
    position: static;
  }
  .comments_controls .comments_box__ctrl {
    top: 260px;
    left: 60%;
  }
  .comments_controls .comments_box__count {
    position: absolute;
    top: 370px;
    left: 390px;
    z-index: 2;
  }
  .footer__info {
    justify-content: center;
    padding: 50px 0 50px 15px;
  }
  .footer__info p {
    width: 230px;
    line-height: 20px;
  }
}

@media all and (max-width: 650px) {
  .info_box {
    flex-direction: column;
  }
  .info_box__body {
    margin: 50px 0 0 70px;
  }
  .info_box__body h3 {
    width: 300px;
  }
  .info_box__body p {
    width: 310px;
  }
  .cat_list__item {
    margin-bottom: 200px;
  }
  .cat_list__item .info {
    width: 340px;
    padding: 0 0 0 100px;
    transform: translate(42%, 90%);
  }
  .cat_list__item .info .btn-bordered {
    left: 100px;
  }
  .cat_list__item .img {
    max-width: 380px;
  }
  .cat_list__item .numb {
    left: 30px;
  }
  .edge_box__item {
    display: none;
    width: 382px;
    height: 245px;
    margin: 0;
    padding: 63px 60px 0 85px;
  }
  .edge_box__item:first-child {
    display: block;
  }
  .edge_box__item .edge_box__ctrl {
    position: absolute;
    display: block;
    top: 5px;
    right: 10px;
  }
  .edge_box__item .edge_box__ctrl li {
    border: 0;
  }
  .routes_box__legend ul {
    flex-direction: column;
  }
  .footer__info p {
    margin-bottom: 27px;
  }
  .news_box {
    margin: 0;
    padding: 0 15px;
  }
  .footer__credits .footer__pay {
    margin-bottom: 20px;
  }
  .footer__credits .footer__dev {
    margin-bottom: 15px;
  }
  .footer__credits .footer__dev li:first-child {
    margin: 0;
  }
}

@media all and (max-width: 500px) {
  .news__column {
    max-width: 100%;
  }
  .info_box {
    margin: 0 15px 80px;
    padding: 0;
  }
  .cat_list__item {
    margin-bottom: 240px;
  }
  .cat_list__item .img {
    width: 300px;
  }
  .cat_list__item .info {
    transform: translate(20%, 90%);
  }
  .routes_box__legend ul {
    margin-left: 15px;
  }
  .comments_box__item .comments_box__body {
    height: 390px;
  }
  .footer__info {
    flex-direction: column;
    padding: 40px 0 50px 15px;
  }
  .footer__contacts {
    padding: 42px 15px 32px 15px;
  }
  .footer__subscribe {
    padding: 0 0 54px 15px;
  }
  .footer__credits {
    padding: 0 80px 0 15px;
  }
}

@media all and (max-width: 425px) {
  .top {
    top: 10px;
  }
  .mobile-menu {
    top: 12px;
  }
  .mfp-container {
    height: 0;
  }
  .navigation {
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
  }
  .navigation .navigation-column {
    height: 100%;
    padding: 20px 30px;
  }
  .navigation .news-column {
    display: none;
    top: 100vh;
    left: 0;
    width: 100%;
    height: 0;
  }
  .navigation .news-column .news-wrap {
    padding: 0 19px;
  }
  .navigation .news-column .news-line {
    padding: 23px 0;
  }
  .navigation .news-column .news-line a {
    font: bold 15px/22px "Roboto Cond", Arial;
  }
  .navigation.nav-expanded {
    max-height: 960px;
  }
  .top_box__title {
    width: 246px;
    margin-left: 50px;
  }
  .top_box__title .top_box__title-txt {
    font: bold 30px/35px 'Proxima Nova', Arial;
    letter-spacing: -.1px;
  }
  .info_box {
    flex-direction: column;
    margin: 0 0 80px 0;
    padding: 0 15px;
  }
  .info_box__body {
    margin: 50px 0 0 40px;
  }
  .info_box__body h3 {
    width: 246px;
    font: bold 24px/30px "Proxima Nova", Arial;
    letter-spacing: -.1px;
  }
  .info_box__body p {
    width: 230px;
  }
  .cat_list__item {
    margin-bottom: 270px;
  }
  .cat_list__item .img {
    width: 246px;
    height: 240px;
  }
  .cat_list__item .info {
    width: 245px;
    height: 245px;
    padding: 45px 0 0 43px;
    transform: translate(19%, 83%);
  }
  .cat_list__item .info h3 {
    font: bold 24px/30px 'Proxima Nova', Arial;
  }
  .cat_list__item .info .btn-bordered {
    left: 43px;
  }
  .cat_list__item .numb {
    transform: translate(0, -220%);
  }
  .cat_list__more {
    margin-bottom: 0;
  }
  .cat_list__more a {
    font: bold 18px/30px 'Proxima Nova', Arial;
  }
  .edge_box__title h3 {
    font: bold 30px/35px "Proxima Nova", Arial;
  }
  .edge_box__in {
    padding-left: 15px;
  }
  .edge_box__item {
    max-width: 290px;
    min-width: 290px;
    padding: 80px 30px 45px 45px;
  }
  .edge_box__ctrl {
    display: block;
  }
  .routes_box {
    margin-bottom: 0;
  }
  .routes_box__title {
    margin: 0 15px 30px;
  }
  .routes_box__title h3 {
    font: bold 30px/35px "Proxima Nova", Arial;
  }
  .routes_box__body {
    height: 252px;
  }
  .routes_box__map {
    height: 252px;
  }
  .routes_box__info--active.routes_box__info--active-mobile {
    max-width: 285px;
    height: 290px;
    transform: translate(-50%, 84%);
  }
  .news_box .news__header {
    margin-top: 50px;
    margin-bottom: 40px;
  }
  .news_box .news__header h3 {
    font: bold 30px/35px "Proxima Nova", Arial;
  }
  .partners_box h3,
  .comments_box h3 {
    font: bold 30px/35px "Proxima Nova", Arial;
  }
  .comments_box {
    margin-bottom: 47px;
    padding-top: 40px;
    padding-bottom: 55px;
  }
  .comments_box__header {
    margin: 0;
  }
  .comments_box__item .comments_box__image {
    max-width: 160px;
    transform: translate(18%, 26%);
  }
  .comments_box__item .comments_box__body {
    width: 100%;
    padding: 85px 30px 45px;
  }
  .comments_controls .comments_box__ctrl {
    top: 175px;
    left: 68%;
  }
  .comments_controls .comments_box__count {
    top: 275px;
    left: 78%;
  }
  .comments_box__slider {
    padding: 0 15px;
  }
  .comments_box__header,
  .partners_box__header {
    padding-left: 15px;
  }
  .partners_box {
    margin-bottom: 53px;
  }
  .footer__contacts {
    margin-bottom: 40px;
  }
  .footer__contacts ul {
    position: absolute;
    display: block;
  }
  .footer__contacts .footer__contacts-phone {
    margin-bottom: 7px;
    margin-right: 100px;
  }
  .footer__contacts .footer__contacts-email {
    margin-right: 100px;
  }
  .footer__contacts .footer__contacts-fb {
    position: absolute;
    top: 1px;
    right: 28px;
  }
  .footer__contacts .footer__contacts-in {
    position: absolute;
    top: 1px;
    right: 40px;
  }
  .footer__subscribe {
    padding: 20px 0 54px 15px;
  }
  .footer__credits {
    padding: 0 30px 0 15px;
  }
}

/* Catamaran */
.top_box__shadow-bt.bg-cat__changeble {
  background-size: cover;
}

.cat_info {
  width: 100%;
  max-width: 700px;
  margin: 0 auto 65px;
  padding-left: 25px;
}

.cat_info h3 {
  margin: 0 0 2px;
  color: #0b6565;
  font: bold 30px/35px "Proxima Nova", Arial;
}

.cat_info h4 {
  margin: 0 0 30px;
  color: #424242;
  font: bold 15px/22px "Roboto Cond", Arial;
  text-transform: uppercase;
}

.cat_info p {
  margin: 0 0 10px;
  color: #424242;
  font: normal 14px/22px "Roboto", Arial;
  letter-spacing: .2px;
}

.cat_specs {
  width: 100%;
  max-width: 770px;
  margin: 0 auto 50px;
  padding-left: 55px;
}

.cat_specs__item {
  width: 28%;
  margin-right: 7%;
  float: left;
}

.cat_specs__item:last-child {
  margin-right: 0;
}

.cat_specs__item dt {
  width: 55%;
  padding: 18px 0;
  float: left;
  color: #808080;
  font: normal 11px/100% "Roboto", Arial;
}

.dl-wrap {
  border-top: 1px solid #e8e8e8;
}

.dl-wrap:last-child {
  border-bottom: 1px solid #e8e8e8;
}

.cat_specs__item dd {
  width: 45%;
  padding: 18px 0;
  float: left;
  color: #424242;
  font: normal 11px/100% "Roboto", Arial;
}

.cat_more {
  padding: 5px 0 30px;
  transition: all 250ms ease;
  overflow: hidden;
}

.cat_more--active {
  margin: 0 0 70px;
  background-color: #f6f6f6;
}

.cat_more__btn {
  display: block;
  margin: 0 auto 50px;
  transition: all 200ms ease;
  outline: none;
  background-color: transparent;
  color: #424242;
  font: bold 10px/21px "Roboto Cond", Arial;
  letter-spacing: 1px;
  cursor: pointer;
  overflow: hidden;
}

.cat_more__btn:hover {
  color: #04888c;
}

.cat_more--active .cat_more__btn:hover,
.cat_more--active .cat_more__btn {
  color: #f6f6f6;
}

.cat_more__btn span {
  display: block;
  position: relative;
  width: 19px;
  height: 19px;
  margin: 10px auto 0;
  transition: all 200ms ease-out;
  border: 1px solid #808080;
  border-radius: 50%;
  text-indent: -9999px;
}

.cat_more__btn:hover span {
  transform: rotate(180deg);
  border-color: #04888c;
  background-color: #04888c;
}

.cat_more--active .cat_more__btn span {
  transform: rotate(45deg);
}

.cat_more__btn span:before,
.cat_more__btn span::after {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-color: #0b6565;
  content: ' ';
}

.cat_more__btn span::before {
  width: 7px;
  height: 1px;
}

.cat_more__btn span::after {
  width: 1px;
  height: 7px;
}

.cat_more__btn:hover span::before,
.cat_more__btn:hover span::after {
  background-color: #fff;
}

.cat_more__in {
  display: none;
  width: 100%;
  max-width: 770px;
  margin: 0 auto 45px;
  padding-left: 55px;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.cat_more_acc {
  opacity: 0;
}

.cat_more--active .cat_more_acc {
  animation: fadein 700ms ease 500ms both;
}

.cat_more_acc__item {
  margin: 0 0 48px;
}

.cat_more_acc__title h4 {
  display: inline-block;
  position: relative;
  padding: 0 0 0 30px;
  color: #424242;
  font: bold 15px/22px "Roboto Cond", Arial;
  text-transform: uppercase;
  cursor: pointer;
}

.cat_more_acc__title h4:hover {
  color: #04888c;
}

.cat_more_acc__title h4:before {
  display: block;
  position: absolute;
  top: 10px;
  left: 2px;
  width: 5px;
  height: 1px;
  background-color: #0b6565;
  content: ' ';
}

.cat_more_acc__title h4::after {
  display: block;
  position: absolute;
  top: 8px;
  left: 4px;
  width: 1px;
  height: 5px;
  transition: transform 250ms ease-out;
  background-color: #0b6565;
  content: ' ';
}

.cat_more_acc__title h4:hover:before {
  left: 1px;
  width: 7px;
}

.cat_more_acc__title h4:hover::after {
  top: 7px;
  height: 7px;
}

.cat_more_acc__item--active .cat_more_acc__title h4::after {
  transform: rotate(90deg);
}

.cat_more_acc__body {
  display: none;
  padding: 35px 0 0 30px;
  overflow: hidden;
}

.cat_more_acc__body p {
  margin: 0 0 15px;
  color: #424242;
  font: normal 14px/22px "Roboto", Arial;
  letter-spacing: .2px;
}

.cat_more_acc__body .cat_specs__item {
  width: 46%;
}

/* Cat video box */
.cat_video_box {
  position: relative;
  width: 100%;
  max-width: 930px;
  margin: 0 auto 70px;
}

.cat_video_box__thumb {
  max-width: 82%;
}

.cat_video_box__thumb .video_container {
  height: 447px;
  overflow: hidden;
}

.cat_video_box__thumb video {
  width: 100%;
  height: 100%;
}

.cat_video_box__body {
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  width: 58%;
  height: 200px;
  background-color: #f6f6f6;
  transform: translate(0, -50%);
}

.cat_video_box__body-in {
  position: relative;
  top: 50%;
  padding-left: 125px;
  transform: translate(0, -50%);
}

.cat_video_box__body-in .play-btn {
  position: absolute;
  left: 40px;
  top: 0;
  bottom: 0;
  width: 43px;
  height: 43px;
  margin: auto;
}

.cat_video_box__body-in h5 {
  margin: 0 0 20px;
  color: #808080;
  font: normal 11px/100% "Roboto", Arial;
}

.cat_video_box__body-in h3 {
  color: #424242;
  font: bold 30px/100% "Proxima Nova", Arial;
}

/* Cat photo gal */
.cat_photo_box {
  position: relative;
  width: 100%;
  max-width: 931px;
  margin: 0 auto 70px;
  padding: 0 0 65px;
}

.cat_photo_box__ctrl {
  position: absolute;
  bottom: 0;
  left: 0;
}

.cat_photo_box__ctrl .arrow {
  position: absolute;
  top: 8px;
  left: 16px;
  width: 24%;
  z-index: 1;
}

.cat_photo_box__ctrl .btn-bg {
  position: absolute;
  top: 0;
  width: 0;
  height: 100%;
  background: #04888c;
  z-index: 0;
}

.cat_photo_box__ctrl .next {
  left: 0;
}

.cat_photo_box__ctrl .prev .btn-bg {
  right: 0;
}

.cat_photo_box__ctrl svg {
  overflow: visible;
}

.cat_photo_box__ctrl ul {
  margin: 0;
  padding: 0 0 0 1px;
  list-style: none;
}

.cat_photo_box__ctrl ul li {
  position: relative;
  width: 44px;
  height: 40px;
  margin: 0 0 0 -1px;
  float: left;
  border: 1px solid #0b6565;
  cursor: pointer;
}

.cat_photo_box__ctrl ul li:hover {
  z-index: 2;
}

.zoom .zoom-img {
  width: 100%;
  height: 0;
  bottom: 0;
  background: #04888c;
}

.zoom .zoom-img svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
}

.cat_photo_box__slider {
  max-width: 50%;
}

.cat_photo_box img {
  height: 100vh;
  max-height: 447px;
  object-fit: cover;
}

.cat_photo_box__item h4,
.cat_photo_box__item p {
  display: none;
}

.cat_photo_box__body {
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  width: 58%;
  height: 275px;
  transform: translate(0, -50%);
  background-color: #f6f6f6;
  z-index: 2;
}

.cat_photo_box__count {
  position: absolute;
  top: 50%;
  left: 28px;
  width: 60px;
  transform: translate(0, -50%);
  color: #808080;
  font: normal 9px/27px "Roboto", Arial;
  text-align: center;
  z-index: 2;
}

.cat_photo_box__count .index {
  display: inline-block;
  color: #0b6565;
  font: 300 20px/35px "Proxima Nova", Arial;
  vertical-align: middle;
}

.cat_photo_box__body-in {
  position: relative;
  top: 50%;
  padding-right: 60px;
  padding-left: 125px;
  transform: translate(0, -50%);
}

.cat_photo_box__body-in h4 {
  margin: 0 0 15px;
  color: #424242;
  font: bold 15px/22px "Roboto Cond", Arial;
  text-transform: uppercase;
}

.cat_photo_box__body-in p {
  max-width: 285px;
  color: #424242;
  font: normal 14px/22px "Roboto", Arial;
  letter-spacing: .2px;
}

/* Bt book */
.bt__book {
  margin: 0 0 70px;
  text-align: center;
}

@media all and (max-width: 769px) {
  .cat_video_box,
  .cat_photo_box {
    max-width: 670px;
    margin: 0 auto 165px;
  }
  .cat_video_box__thumb .video_container {
    height: 292px;
  }
  .cat_video_box__body {
    width: 69%;
    transform: translate(0, 22%);
  }
  .cat_photo_box {
    margin: 0 auto 130px;
  }
  .cat_photo_box img {
    max-height: 320px;
  }
  .cat_photo_box__body {
    width: 62%;
    transform: translate(0, -7%);
  }
  .bt__book {
    margin: 0 0 120px;
  }
}

@media all and (max-width: 650px) {
  .cat_info,
  .cat_specs {
    padding-left: 15px;
  }
  .cat_more__in {
    padding-left: 30px;
  }
  .cat_specs__item {
    margin-right: 4%;
  }
  .cat_specs__item {
    width: 98%;
  }
  .cat_specs__item dt {
    width: 60%;
  }
  .cat_specs__item dd {
    width: 40%;
  }
  .dl-wrap {
    border-bottom: 1px solid #e8e8e8;
  }
  .dl-wrap:first-child {
    border-top: 1px solid #e8e8e8;
  }
  .cat_more_acc__body .cat_specs__item {
    width: 93%;
  }
  .cat_more_acc__body dd {
    padding-left: 30px;
  }
  .cat_video_box,
  .cat_photo_box {
    padding-left: 15px;
  }
  .cat_photo_box {
    margin: 0 auto 150px;
  }
  .cat_photo_box img {
    max-height: 240px;
  }
  .cat_video_box__body {
    transform: translate(-5%, 22%);
  }
  .cat_photo_box__body {
    transform: translate(-5%, 0);
  }
  .cat_photo_box__body-in {
    top: 60%;
    padding-left: 45px;
    padding-right: 15px;
  }
  .cat_photo_box__ctrl {
    left: 15px;
  }
  .cat_photo_box__count {
    top: 18%;
    left: 24px;
  }
  .bt__book {
    margin: 0 0 80px;
  }
}

@media all and (max-width: 500px) {
  .cat_photo_box {
    margin: 0 auto 180px;
  }
  .cat_photo_box__ctrl {
    bottom: 27%;
    left: 65%;
    z-index: 2;
  }
}

@media all and (max-width: 425px) {
  .top_cat_info h1 {
    font: bold 30px/35px 'Proxima Nova', Arial;
    letter-spacing: -.1px;
  }
  .top_cat_info h5 {
    margin: 0 0 5px;
  }
  .top_box {
    margin: 0 0 25px;
  }
  .cat_info,
  .cat_specs {
    padding-right: 15px;
  }
  .cat_info h3 {
    font: bold 24px/30px "Proxima Nova", Arial;
    letter-spacing: -.1px;
  }
  .dl-wrap {
    border-bottom: 0;
  }
  .dl-wrap:last-child {
    border-bottom: 0;
  }
  .cat_video_box {
    margin: 0 auto 127px;
  }
  .cat_video_box .video_container {
    width: 288px;
    height: 169px;
  }
  .cat_video_box__body {
    width: 260px;
    height: 105px;
    transform: translate(0, 55%);
  }
  .cat_video_box__body-in {
    padding-left: 85px;
  }
  .cat_video_box__body-in h5 {
    margin: 0 0 12px;
  }
  .cat_video_box__body-in h3 {
    font: bold 24px/100% "Proxima Nova", Arial;
  }
  .cat_video_box__body-in:before {
    left: 15px;
  }
  .cat_photo_box {
    margin: 0 auto 243px;
  }
  .cat_photo_box__slider {
    max-width: 246px;
  }
  .cat_photo_box__body {
    transform: translate(0, 0);
    width: 262px;
    height: 346px;
  }
  .cat_photo_box__count {
    top: 50px;
    left: 27px;
  }
  .cat_photo_box__ctrl {
    left: 55%;
  }
}

.contact_head {
  width: 50%;
  padding: 130px 0 55px 0;
  text-align: center;
}

.contact_head h1 {
  color: #0b6565;
  font: bold 30px/35px "Proxima Nova", Arial;
  letter-spacing: -.1px;
}

.contact_map_box {
  position: relative;
  max-width: 985px;
  width: 100%;
  margin: 0 auto 150px;
}

.contact_map {
  width: 505px;
  height: 447px;
}

.contact_map__info {
  position: absolute;
  top: 50%;
  width: 55%;
  height: 225px;
  background: #f6f6f6;
  transform: translate(79%, -45%);
}

.map__info_box {
  position: relative;
  top: 50%;
  padding-left: 23%;
  transform: translate(0, -50%);
}

.map__info_box .info_ref {
  margin-left: 30px;
  color: #424242;
  font: normal 14px/22px "Roboto", Arial;
  text-decoration: none;
}

.map__info_box .info_ref:hover {
  color: #04888c;
  text-decoration: underline;
}

.map__info_box .info {
  margin-bottom: 12px;
}

.map__info_box .info:last-child {
  margin-bottom: 0;
}

.map__info_box .info_ico-telephone {
  width: 15px;
  height: 15px;
  display: inline-block;
  background: url("../images/icons/ico-phone-reverse.svg") no-repeat;
  vertical-align: middle;
}

.map__info_box .info_ico-mail {
  width: 11px;
  height: 8px;
  display: inline-block;
  background: url("../images/icons/ico-mail.svg") no-repeat;
}

.map__info_box .info_ico-address {
  width: 12px;
  height: 11px;
  display: inline-block;
  background: url("../images/icons/ico-house.svg") no-repeat;
}

.info_contact {
  display: none;
}

.contact_faq {
  max-width: 675px;
  width: 100%;
  margin: 0 auto 125px;
}

.contact_faq h2 {
  color: #0b6565;
  font: bold 30px/35px "Proxima Nova", Arial;
  letter-spacing: -.1px;
}

.contact_faq_in {
  margin-top: 50px;
}

.contact_acc__item {
  margin: 0 0 48px 10px;
}

.contact_acc__title h4 {
  display: inline-block;
  position: relative;
  padding: 0 0 0 30px;
  color: #424242;
  font: bold 15px/22px "Roboto Cond", Arial;
  text-transform: uppercase;
  letter-spacing: .5px;
  cursor: pointer;
}

.contact_acc__title h4:hover {
  color: #04888c;
}

.contact_acc__title h4:before {
  display: block;
  position: absolute;
  top: 10px;
  left: 2px;
  width: 5px;
  height: 1px;
  background-color: #0b6565;
  content: ' ';
}

.contact_acc__title h4::after {
  display: block;
  position: absolute;
  top: 8px;
  left: 4px;
  width: 1px;
  height: 5px;
  transition: transform 250ms ease-out;
  background-color: #0b6565;
  content: ' ';
}

.contact_acc__title h4:hover:before {
  left: 1px;
  width: 7px;
}

.contact_acc__title h4:hover::after {
  top: 7px;
  height: 7px;
}

.contact_acc__item--active .contact_acc__title h4::after {
  transform: rotate(90deg);
}

.contact_acc__body {
  display: none;
  padding: 35px 0 0 68px;
  overflow: hidden;
}

.contact_acc__body p {
  max-width: 589px;
  margin: 0 0 15px;
  color: #424242;
  font: normal 14px/22px "Roboto", Arial;
  letter-spacing: .2px;
}

.contact_acc__body .cat_specs__item {
  width: 46%;
}

.contact_form {
  position: relative;
  width: 68%;
  margin: 0 auto 150px;
}

.contact_form h2 {
  margin-bottom: 50px;
  color: #0b6565;
  font: bold 30px/35px "Proxima Nova", Arial;
  letter-spacing: -.1px;
}

.contact_form .input-line {
  max-width: 545px;
  margin-bottom: 30px;
}

.contact_form .checkbox-line {
  margin-top: 40px;
  margin-bottom: 40px;
}

.contact_form .checkbox-line label {
  color: #424242;
  font: normal 14px/22px "Roboto", Arial;
  letter-spacing: .2px;
}

.contact_form .captcha-wrap {
  margin-bottom: 40px;
}

.contact_form .form-error-box {
  position: absolute;
  left: 300px;
  bottom: 0;
  width: 247px;
}

.contact_form .form-error-box .error-box__item {
  margin-bottom: 10px;
}

@media all and (max-width: 800px) {
  .contact_map_box {
    margin-bottom: 225px;
  }
  .contact_map__info {
    transform: translate(62%, 56%);
  }
  .map__info_box .info_contact {
    display: none;
  }
  .contact_acc__body p {
    max-width: 515px;
  }
}

@media all and (max-width: 650px) {
  .contact_head {
    padding: 50px;
  }
  .contact_head h1 {
    display: none;
  }
  .contact_map {
    width: 100%;
    height: 315px;
  }
  .contact_map_box {
    margin-bottom: 280px;
  }
  .contact_map__info {
    width: 302px;
    transform: translate(6%, 58%);
  }
  .map__info_box {
    padding-left: 15%;
  }
  .map__info_box .info_contact {
    display: block;
    margin-bottom: 26px;
    color: #0b6565;
    font: bold 24px/30px 'Proxima Nova', Arial;
    letter-spacing: -.1px;
  }
  .contact_form .form-error-box {
    left: 0;
    bottom: -160px;
    width: 100%;
    height: 155px;
  }
}

@media all and (max-width: 425px) {
  .contact_head {
    padding: 30px;
  }
  .contact_faq h2,
  .contact_form h2 {
    margin-left: 15px;
    color: #0b6565;
    font: bold 24px/30px "Proxima Nova", Arial;
  }
  .contact_form {
    width: 100%;
    padding: 0 15px;
  }
  .contact_form h2 {
    margin-left: 0;
  }
  .contact_form .form-error-box {
    padding-left: 15px;
  }
  .contact_faq_acc {
    margin: 0 15px;
  }
  .contact_acc__body {
    padding-right: 15px;
  }
}

.top_box__shadow-bt.bg-comp {
  background-image: url("../images/bg-main.jpg");
  background-size: cover;
}

.bg-comp h1 {
  margin: 0 0 50px 0;
}

.comp-info {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.comp-info h3 {
  align-self: center;
  max-width: 565px;
  width: 100%;
  margin-bottom: 70px;
  font: bold 30px/35px "Proxima Nova";
  color: #0b6565;
}

.comp-info .comp-info_box {
  display: flex;
  margin-bottom: 70px;
}

.comp-info img {
  max-width: 378px;
  max-height: 320px;
  height: 100%;
  object-fit: cover;
}

.comp-info .comp-info_box__thumb {
  align-self: center;
  max-width: 384px;
  margin-left: 9%;
}

.comp-info .comp-info__main {
  align-self: center;
  max-width: 635px;
  margin-left: 73px;
  margin-bottom: 150px;
}

.comp_team {
  max-width: 955px;
  margin: 0 auto 260px;
}

.comp_team h3 {
  margin-left: 6%;
  margin-bottom: 70px;
}

.comp_team .comp__ctrl {
  width: 90px;
  margin: 70px auto;
}

.team_member__container {
  display: flex;
}

.team_member__container .member_img img {
  max-width: 117px;
  max-height: 117px;
  height: 100vh;
  object-fit: cover;
}

.team_member__container .member_box {
  margin-left: 6%;
  margin-top: 7px;
}

.team_member__container .member_head {
  margin-bottom: 15px;
}

.team_member__container .member_head span {
  font: normal 11px/11px "Roboto";
  color: #808080;
  letter-spacing: .3px;
}

.team_member__container .member_info {
  max-width: 250px;
}

.team_member__container .comp__mobile-ctrl {
  display: none;
}

.comp_activity {
  max-width: 850px;
  margin: 0 auto 100px;
}

.comp_activity h3 {
  max-width: 690px;
  margin-bottom: 70px;
}

.comp_activity__block {
  display: flex;
  margin-bottom: 60px;
}

.comp_activity__block .activity_box {
  padding-bottom: 25px;
  border-bottom: 2px solid #e8e8e8;
}

.comp_activity__block:last-child .activity_box {
  border-bottom: 0;
}

.comp_activity__block .activity_ico {
  width: 63px;
  height: 63px;
  margin: 40px 70px 0 35px;
  text-align: right;
}

.comp_activity__block .activity_head {
  margin-bottom: 15px;
}

.comp_activity__block .activity_head h4 {
  font: bold 15px/22px "Roboto Cond";
  color: #424242;
  text-transform: uppercase;
  letter-spacing: .5px;
}

.comp_activity__block .activity_body {
  max-width: 587px;
  margin-bottom: 40px;
}

.activity_details .btn-more {
  position: relative;
}

@media all and (min-width: 1070px) {
  .comp-info_box {
    justify-content: center;
  }
}

@media all and (max-width: 880px) {
  .comp-info h3 {
    max-width: 458px;
  }
  .comp-info .comp-info_box {
    height: 245px;
  }
  .comp-info .comp-info_box__image img {
    max-width: 290px;
  }
  .comp-info .comp-info_box__thumb {
    max-width: 340px;
  }
  .comp-info .comp-info__main {
    margin: 0 auto 150px;
  }
  .comp_team {
    position: relative;
    margin: 0 auto 150px;
  }
  .comp_team .team_member__container {
    margin-left: 6%;
  }
  .comp_team .comp__ctrl {
    position: absolute;
    top: 65%;
    right: 15%;
    margin: 0;
    z-index: 2;
  }
  .comp_activity h3 {
    margin-left: 6%;
  }
  .comp_activity__block {
    padding: 0 15px;
  }
  .comp_activity__block .activity_body {
    max-width: 500px;
  }
}

@media all and (max-width: 650px) {
  .comp-info {
    margin-bottom: 150px;
  }
  .comp-info .comp-info_box {
    flex-direction: column;
    height: auto;
    margin-bottom: 25px;
  }
  .comp-info .comp-info_box img {
    max-width: 300px;
    margin-bottom: 45px;
  }
  .comp-info .comp-info_box__thumb {
    max-width: 635px;
    margin: 0 15px;
  }
  .comp-info .comp-info__main {
    margin: 0 15px;
  }
  .comp_team {
    margin-bottom: 110px;
  }
  .comp_team .comp__ctrl {
    top: 42%;
  }
  .team_member__container {
    flex-direction: column;
  }
  .team_member__container .member_box {
    margin-left: 0;
    margin-top: 35px;
  }
}

@media all and (max-width: 425px) {
  .comp-info {
    margin-bottom: 75px;
  }
  .comp-info h3 {
    max-width: 290px;
    margin-bottom: 45px;
    font: bold 24px/30px "Proxima Nova";
    letter-spacing: -.1px;
  }
  .comp_team {
    margin-bottom: 75px;
  }
  .comp_team h3 {
    margin-bottom: 35px;
    font: bold 24px/30px "Proxima Nova";
    letter-spacing: -.1px;
  }
  .comp_team .comp__ctrl {
    top: 33%;
  }
  .comp_activity {
    margin-bottom: 75px;
  }
  .comp_activity h3 {
    margin-bottom: 60px;
    margin-left: 0;
    padding: 0 15px;
    font: bold 30px/30px "Proxima Nova";
    letter-spacing: -.1px;
  }
  .comp_activity__block {
    flex-direction: column;
  }
  .comp_activity__block .activity_box {
    margin-left: 0;
  }
  .comp_activity__block .activity_ico {
    margin: 0 0 35px 5px;
  }
}

.mfp-wrap {
  transform: translateZ(0);
}

.mfp-close-btn-in .mfp-close {
  color: #fff;
}

.mfp-close {
  width: 110px;
  height: 40px;
  opacity: 1;
  cursor: pointer;
}

.close-text.mfp-close,
.close-ico.mfp-close {
  position: relative;
  margin: 10px 0;
  font: bold 10px/21px "Roboto Cond";
}

.mfp-container {
  padding: 0;
}

.body-under-popup {
  width: 100%;
  min-height: 686px;
  background: #000;
  opacity: .5;
}

.connect-logo {
  display: none;
}

.connect-popup,
.subscribe-popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: 590px;
  min-height: 415px;
  margin: 0 auto;
  padding: 70px 155px 28px;
  background: #fff;
}

.connect-popup .con-head,
.connect-popup .subscr-head,
.subscribe-popup .con-head,
.subscribe-popup .subscr-head {
  font: bold 15px/22px "Roboto Cond";
  color: #424242;
  text-transform: uppercase;
  letter-spacing: .5px;
}

.connect-form,
.subscribe-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.connect-form input,
.subscribe-form input {
  margin: 38px 0;
  width: 160px;
  font: bold 18px/24px "Proxima Nova";
  color: #424242;
}

.connect-form .input--submit,
.subscribe-form .input--submit {
  margin-bottom: 55px;
}

.con-social-group .social-ico {
  display: inline-block;
  width: 17px;
  height: 17px;
  margin: 30px 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  vertical-align: middle;
}

.con-social-group .social-speech {
  background-image: url("../images/icons/ico-speech-bubble.svg");
}

.con-social-group .social-skype {
  background-image: url("../images/icons/ico-skype-logo.svg");
}

.con-social-group .social-messenger {
  background-image: url("../images/icons/ico-messenger.svg");
}

.con-social-group .social-mail {
  height: 11px;
  background-image: url("../images/icons/ico-mail-logo.svg");
}

.connect-error-box {
  position: absolute;
  top: 190px;
}

.connect-error-box,
.subscribe-error-box {
  left: 50%;
  transform: translateX(-50%);
}

@media all and (max-width: 650px) {
  .connect-popup,
  .subscribe-popup {
    min-width: 390px;
    padding: 0;
  }
}

@media all and (max-width: 425px) {
  .connect-popup,
  .subscribe-popup {
    min-width: 320px;
  }
  .connect-logo {
    display: block;
    align-self: baseline;
    margin: 10px 0 35px 15px;
  }
  .connect-logo img {
    height: 39px;
  }
  .close-popup {
    top: 0;
    margin-top: 10px;
    padding: 10px 16px 10px 12px;
    background: #26b0b0;
  }
  .connect-error-box {
    top: 210px;
    width: 100%;
    text-align: center;
  }
}

.thanks-popup {
  position: relative;
  max-width: 587px;
  margin: 0 auto;
  padding: 60px 100px 65px;
  background-color: #fff;
  text-align: center;
}

.thanks-head {
  margin-bottom: 33px;
  font: bold 30px/35px "Proxima Nova";
  color: #0b6565;
  letter-spacing: -.1px;
}

.thanks-body {
  font: normal 14px/22px "Roboto";
  color: #424242;
  letter-spacing: .2px;
}

@media all and (max-width: 650px) {
  .thanks-popup {
    padding: 50px 20px;
    min-width: 390px;
  }
}

@media all and (max-width: 425px) {
  .thanks-popup {
    min-width: 320px;
  }
  .thanks-head {
    margin-top: 65px;
  }
}

.subscribe-popup {
  padding: 62px 155px 10px;
  min-height: 300px;
}

.subscribe-popup .subscribe-error-box {
  position: absolute;
  top: 180px;
}

.subscribe-form input {
  width: 340px;
  margin: 35px 0;
}

form .errorClass {
  color: #ff4343;
}

@media all and (max-width: 425px) {
  .subscribe-popup {
    padding: 0;
  }
  .subscribe-popup .subscribe-error-box {
    top: 205px;
    width: 100%;
    text-align: center;
  }
  .subscribe-form input {
    width: 290px;
  }
}

.page404-container, .page404-title, .old-browser-container {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.page404-container, .old-browser-container {
  position: relative;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 auto;
  max-width: 930px;
  width: 100%;
  padding: 0 25px;
  padding-bottom: 70px;
  min-height: 400px;
}

.page404-container {
  height: calc(100vh - 221px);
}

.old-browser-container {
  height: calc(100vh - 295px);
}

.page404-container .logo-wrap, .old-browser-container .logo-wrap {
  position: absolute;
  top: -40px;
  left: 25px;
}

.page404-title {
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 40px;
  padding-top: 35px;
  max-width: 796px;
  width: 100%;
  height: 297px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI3OTciIGhlaWdodD0iMjk4IiB2aWV3Qm94PSIwIDAgNzk3IDI5OCI+ICAgIDxwYXRoIGZpbGw9IiMyNkFGQUYiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTI0OC4zNjQgMTgyLjc4N0gyMTMuOTZWNi41MTJoLTg2LjY1MUwuMzA1IDE3NS45OTJ2NjIuODY0SDE0OC4xMlYyOTEuMWg2NS44Mzh2LTUyLjI0NWgzNC40MDV2LTU2LjA2OXptLTE3Ny4xMjQgMEwxNDguMTIgNzQuMDV2MTA4LjczOEg3MS4yNHpNMzA4LjY4IDQxLjM0MmMtMjIuOTM3IDI3LjE4NS0zNC40MDUgNjIuODY1LTM0LjQwNSAxMDcuNDY0IDAgNDQuNiAxMS40NjggODAuMjggMzQuNDA1IDEwNy44OSAyMi45MzcgMjcuMTg0IDUzLjA5NSA0MC43NzYgOTAuODk5IDQwLjc3NnM2Ny45NjItMTMuNTkyIDkwLjg5OS00MC43NzdjMjMuMzYxLTI3LjYwOSAzNC44My02My4yODkgMzQuODMtMTA3Ljg4OSAwLTQ0LjYtMTEuNDY5LTgwLjI4LTM0LjgzLTEwNy40NjRDNDY3LjU0IDEzLjczMiA0MzcuMzgzLjE0IDM5OS41NzguMTRjLTM3LjgwMyAwLTY3Ljk2IDEzLjU5My05MC44OTggNDEuMjAyem01MC45NzEgMTcxLjE3OGMtOS43NjktMTUuNzE2LTE0Ljg2Ni0zNi45NTQtMTQuODY2LTYzLjcxNCAwLTI2Ljc2IDUuMDk3LTQ3LjU3MyAxNC44NjYtNjMuMjg5IDkuNzctMTUuNzE2IDIzLjM2Mi0yMy43ODYgMzkuOTI4LTIzLjc4NnMzMC4xNTggOC4wNyAzOS45MjcgMjMuNzg2YzEwLjE5NSAxNS43MTYgMTUuMjkyIDM2LjUzIDE1LjI5MiA2My4yOSAwIDI2Ljc2LTUuMDk3IDQ3Ljk5Ny0xNS4yOTIgNjMuNzEzLTkuNzcgMTUuNzE3LTIzLjM2MSAyMy4zNjItMzkuOTI3IDIzLjM2MnMtMzAuMTU4LTcuNjQ1LTM5LjkyOC0yMy4zNjJ6bTQzNi42NTQtMjkuNzMzaC0zNC40MDZWNi41MTJoLTg2LjY1bC0xMjcuMDA0IDE2OS40OHY2Mi44NjRINjk2LjA2VjI5MS4xSDc2MS45di01Mi4yNDVoMzQuNDA2di01Ni4wNjl6bS0xNzcuMTI1IDBMNjk2LjA2IDc0LjA1djEwOC43MzhINjE5LjE4eiIgb3BhY2l0eT0iLjA1Ii8+PC9zdmc+);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.page404-title h1 {
  margin-bottom: 50px;
  max-width: 380px;
  text-align: center;
  font-size: 40px;
  line-height: 40px;
  font: normal 45px/45px "Proxima Nova";
  color: #0b6565;
}

.page404-content {
  width: 100%;
  max-width: 300px;
  font-size: 14px;
  line-height: 22px;
}

.page404-content__title {
  margin-bottom: 20px;
  text-align: center;
}

.page404-menu {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 30px;
}

.btn-wrap {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 55px;
}

.old-browser-page {
  padding-top: 30px;
}

.old-browser-page .site-logo {
  display: block;
  margin: 0 auto;
  width: 73px;
}

.old-browser__title {
  margin-bottom: 50px;
  width: 100%;
  max-width: 300px;
  text-align: center;
  font: normal 14px/22px "Roboto";
  color: #424242;
  letter-spacing: .2px;
}

.browser-list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 624px;
  width: 100%;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: center;
}

.browser-list__link {
  display: block;
  width: 40px;
  text-decoration: none;
}

.browser-list__link svg g, .browser-list__link svg path {
  fill: grey;
}

.browser-list__link:hover svg g, .browser-list__link:hover svg path {
  fill: #04888C;
}

.browser-list__link:hover .browser-list__link-title {
  color: #04888C;
}

.browser-list__link-title {
  font-weight: bold;
  text-transform: uppercase;
  color: grey;
  font-size: 10px;
}

.parked-page-container {
  padding-top: 110px;
}

.parked-page-container h1 {
  margin-bottom: 19px;
  max-width: 320px;
  font: bold 15px/22px "Roboto Cond";
  color: #424242;
  text-transform: uppercase;
  letter-spacing: .45px;
}

.parked-page-container .page404-title {
  padding-top: 120px;
  background: url("../images/parked-page-bg.png") center no-repeat;
}

.parked-page-container .btn-bordered {
  background: transparent;
}

.wrapper {
  margin: 0 auto;
  max-width: 1024px;
  width: 100%;
  padding: 0 48px 0 46px;
}

.blog .news__column {
  margin-bottom: 60px;
}

.blog-filter {
  display: flex;
  margin-top: 30px;
  width: 100%;
}

.blog-filter__item {
  margin-right: 40px;
}

.blog-filter__link {
  font: normal 14px/22px "Roboto";
  letter-spacing: 0.2px;
  text-decoration: none;
  color: #424242;
}

.blog-filter__link--active {
  color: #04888c;
}

.blog .btn-wrap {
  margin-top: 0;
  width: 100%;
}

.blog-more__news {
  outline: none;
  border: none;
  font: 600 25px/35px "Proxima Nova";
  color: #04888c;
  background: transparent;
}

.blog-article {
  padding-bottom: 95px;
  font: normal 14px/22px 'Roboto';
  color: #424242;
}

.blog-article-top {
  position: relative;
  padding-top: 130px;
  padding-bottom: 60px;
}

.blog-article-top--img {
  padding-top: 300px;
}

.blog-article-top--img:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.35), white);
  z-index: -1;
}

.blog-article-top--dark:before {
  background: rgba(0, 0, 0, 0.35);
}

.blog-article__bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -2;
}

.blog-article__date {
  display: block;
  margin-bottom: 37px;
}

.blog-article__date, .blog-article .quote-author {
  font: normal 11px/18px "Roboto";
  color: #808080;
  letter-spacing: .33px;
}

.blog-article__heading {
  margin-bottom: 25px;
}

.blog-article__subheading {
  font: bold 15px/1.47 "Roboto";
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.blog-article-header, .blog-article__text,
.blog-article .text-list, .blog-article__subheading {
  margin-left: 13.6%;
}

.blog-article__text, .blog-article__subheading,
.blog-article .routes_box {
  margin-bottom: 50px;
}

.blog-article-header {
  max-width: 550px;
}

.blog-article__text, .blog-article__subheading {
  max-width: 630px;
}

.blog-article .cat_video_box__body {
  padding-right: 75px;
}

.blog-article .routes_box__legend {
  display: flex;
  justify-content: space-between;
  margin: 0;
  width: 85%;
}

.blog-article .route-distance {
  display: none;
}

.blog-article .cat_video_box__body {
  display: flex;
  align-items: center;
  min-height: 200px;
  height: auto;
}

.blog-article .cat_video_box__body-in {
  top: 0;
  transform: translate(0%, 0%);
  width: 100%;
  height: 100%;
  padding: 25px 25px 25px 125px;
}

.blog-article .play-btn {
  background: transparent;
}

.blog-article .routes_box__legend ul {
  margin: 0 auto;
}

.text-list-widget + * {
  margin-top: 50px;
}

.share-list__link path:not(#a) {
  fill: #04888C;
}

.share-list__link:hover path {
  opacity: .7;
}

.blog-article-top--img {
  margin-bottom: 65px;
  padding-bottom: 75px;
}

.blog-article-top--img .blog-article-header {
  margin-bottom: 0;
}

.blog-image__slider {
  max-width: 90%;
}

.slider-copyright .cat_photo_box__count {
  display: none;
}

.slider-copyright .cat_photo_box__body {
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: calc(100% - 65px);
  transform: translate(0%, 0%);
  background: transparent;
}

.slider-copyright .cat_photo_box__body-in {
  top: auto;
  transform: translate(0%, 0%);
  padding: 15px;
  max-width: 285px;
}

.blog-article-top--dark * {
  color: #fff;
}

.blog-article-top--dark * .share-list__link path:not(#a) {
  fill: #fff;
}

.blog-article-header .blog-article__subheading {
  margin-left: 0;
  margin-bottom: 40px;
}

.share-box, .share-list {
  display: flex;
  align-items: center;
}

.share-box__title {
  margin-right: 45px;
  font-size: 14px;
}

.share-list {
  justify-content: space-between;
  width: 80px;
}

.quote {
  position: relative;
  display: flex;
  margin-left: 9%;
  margin-bottom: 50px;
  max-width: 715px;
  padding: 45px 0;
  background-color: #fafafa;
}

.quote:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 80px;
  height: 100%;
  background: #fff;
}

.quote-img__wrap {
  position: relative;
  margin-right: 35px;
  flex-shrink: 0;
  width: 160px;
  height: 160px;
}

.quote-img__item {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.quote-text {
  position: relative;
  margin: 5px 0;
  padding: 0 65px;
  font: bold 18px/24px 'Proxima Nova';
}

.quote-text:before {
  content: '';
  position: absolute;
  left: 0;
  top: 8px;
  width: 15px;
  height: 11px;
  background: url("../images/icons/quote-ico.svg") center no-repeat;
}

.quote-author {
  margin-top: 15px;
  text-align: right;
}

.article-list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  counter-reset: item;
}

.article-list .owl-nav {
  display: flex;
}

.article-list .owl-prev,
.article-list .owl-next {
  width: 40px;
  height: 40px;
  border: 1px solid #0b6565;
  font-size: 0;
  background: center no-repeat;
}

.article-list .owl-next {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5IiBoZWlnaHQ9IjYiIHZpZXdCb3g9IjAgMCA5IDYiPiAgICA8cGF0aCBmaWxsPSIjMEI2NTY1IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01IDB2Mi4xOTNIMHYxaDV2Mi4xOTRsMy4zNjctMi42OTN6Ii8+PC9zdmc+);
  border-left: none;
}

.article-list .owl-prev {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5IiBoZWlnaHQ9IjYiIHZpZXdCb3g9IjAgMCA5IDYiPiAgICA8cGF0aCBmaWxsPSIjMEI2NTY1IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zLjM2NyAwdjIuMTkzaDV2MWgtNXYyLjE5NEwwIDIuNjk0eiIvPjwvc3ZnPg==);
}

.article-list__item {
  position: relative;
  margin-bottom: 50px;
  width: 44%;
  max-width: 340px;
  padding-left: 85px;
  counter-increment: item;
  list-style-type: none;
}

.article-list__item:nth-child(odd) {
  margin-right: 80px;
}

.article-list__item:before {
  content: counter(item, decimal-leading-zero) "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 50px;
  height: 50px;
  text-align: center;
  vertical-align: middle;
  color: #fff;
  line-height: 50px;
  background-color: #0b6565;
}

.text-list__item {
  position: relative;
  margin-bottom: 25px;
  max-width: 630px;
  list-style-type: none;
}

.text-list__item:before {
  content: '';
  position: absolute;
  left: -20px;
  top: 10px;
  display: block;
  width: 10px;
  height: 2px;
  background-color: #0b6565;
}

.article-banner {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 684px;
  min-height: 106px;
  padding: 30px 46px 13px 46px;
  background-color: #26b0b0;
  color: #fff;
  z-index: 9999;
}

.article-banner-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}

.article-banner__heading {
  margin-right: 30px;
  margin-bottom: 17px;
  text-transform: uppercase;
  font: normal 15px/22px "Roboto Cond";
}

.article-banner__link {
  position: relative;
  display: block;
  margin-bottom: 17px;
  height: 22px;
  padding-right: 25px;
  text-decoration: none;
  text-transform: uppercase;
  font: bold 10px/21px "Roboto Cond";
  letter-spacing: 1px;
  color: #fff;
}

.article-banner__link::after, .article-banner__link::before {
  content: ' ';
  display: block;
  position: absolute;
  top: 50%;
}

.article-banner__link::after {
  right: -6px;
  width: 0;
  height: 0;
  transform: translate(0, -50%) scale(1.5, 1.1);
  border: 3px solid transparent;
  border-left-color: #333;
}

.article-banner__link::before {
  right: 0;
  width: 8px;
  height: 1.3px;
  transform: translate(0, -50%);
  transition: width 250ms ease-out;
  background-color: #333;
}

.close-more-menu,
.article-banner__close {
  position: absolute;
  left: 22px;
  top: 22px;
  width: 16px;
  height: 16px;
  outline: none;
  background-color: transparent;
}

.close-more-menu:before, .close-more-menu:after,
.article-banner__close:before,
.article-banner__close:after {
  content: ' ';
  position: absolute;
  top: 3px;
  left: 50%;
  height: 12px;
  width: 1px;
  background-color: #333;
}

.close-more-menu:before,
.article-banner__close:before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.close-more-menu:after,
.article-banner__close:after {
  transform: rotate(-45deg);
}

.route-distance {
  padding-left: 20px;
  border-left: 2px solid #e8e8e8;
}

.route-distance label {
  cursor: default;
  letter-spacing: 0.3px;
  color: #808080;
}

.route-distance input {
  width: 80px;
  text-align: right;
  font: bold 15px/22px "Roboto Cond";
  color: #0b6565;
}

.place-heading {
  margin-bottom: 5px;
  letter-spacing: 0.4px;
  text-align: center;
  font: bold 15px/22px "Roboto Cond";
  color: #0b6565;
}

.place-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.place-image__wrap {
  margin-bottom: 11px;
  width: 207px;
  height: 106px;
}

.place-image__wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.place__link {
  position: relative;
  padding-right: 25px;
  text-decoration: none;
  text-transform: uppercase;
  font: bold 10px/21px "Roboto Cond";
  background: transparent;
  outline: none;
  color: #424242;
}

.place__link::after, .place__link::before {
  content: ' ';
  display: block;
  position: absolute;
  top: 50%;
}

.place__link::after {
  right: -6px;
  width: 0;
  height: 0;
  transform: translate(0, -50%) scale(1.5, 1.1);
  border: 3px solid transparent;
  border-left-color: #0b6565;
}

.place__link::before {
  right: 0;
  width: 8px;
  height: 1.3px;
  transform: translate(0, -50%);
  transition: width 250ms ease-out;
  background-color: #0b6565;
}

.photo-copyright-widget .cat_photo_box {
  display: flex;
  align-items: center;
}

.photo-copyright-widget .cat_photo_box__body {
  position: relative;
}

.cat_photo_box__ctrl.opacity {
  opacity: 0;
}

.blog-article-map .gm-style-iw {
  left: 25px !important;
}

@media all and (max-width: 950px) {
  .blog-article .routes_box__legend {
    flex-direction: column-reverse;
    padding-left: 15px;
  }
  .blog-article .route-distance {
    margin-bottom: 20px;
    padding-bottom: 20px;
    padding-left: 0;
    width: 100%;
    border-bottom: 2px solid #e8e8e8;
    border-left: none;
  }
  .blog-article .route-distance input {
    text-align: left;
  }
  .blog-article .list-unstyled, .blog-article .navigation-column h5, .navigation-column .blog-article h5 {
    margin-left: 0 !important;
  }
}

@media all and (max-width: 767px) {
  .wrapper {
    padding: 0 15px;
  }
  .blog-article-header, .blog-article__text,
  .blog-article .text-list, .blog-article__subheading {
    margin-left: 0;
  }
  .blog-article .cat_video_box__body {
    padding-right: 0;
  }
  .blog-article .slider-copyright {
    margin-bottom: 50px;
  }
  .blog-article .cat_video_box {
    margin-bottom: 40px;
    padding-left: 0 !important;
  }
  .blog-article .cat_video_box__body {
    position: relative;
    margin: -90px 0 0 auto;
    transform: translate(0%, 0%) !important;
  }
  .photo-widget .cat_photo_box {
    margin-bottom: 50px;
  }
  .article-list__item {
    width: 100%;
  }
  .quote {
    margin-left: 0;
  }
  .quote-text {
    padding: 0 43px;
  }
  .text-list-widget {
    padding-left: 42px;
  }
  .blog-image__slider {
    width: 100%;
  }
  .article-list {
    position: relative;
    max-width: 340px;
  }
  .article-list .owl-nav {
    position: absolute;
    top: 0;
    right: 0;
  }
  .article-list__item {
    padding-top: 80px;
    padding-left: 0;
  }
}

@media all and (max-width: 600px) {
  .quote {
    flex-direction: column;
  }
  .quote:before {
    width: 100%;
    height: 105px;
  }
  .quote-text:before {
    top: auto;
    left: auto;
    right: 14px;
    bottom: -30px;
  }
  .quote-img__wrap {
    margin-bottom: 35px;
    margin-left: 43px;
  }
  .quote-author {
    text-align: left;
  }
  .photo-copyright-widget .cat_photo_box {
    flex-direction: column;
    max-width: 320px;
    padding-bottom: 0;
  }
  .photo-copyright-widget .cat_photo_box__slider {
    margin-bottom: 50px;
    max-width: 100%;
  }
  .photo-copyright-widget .cat_photo_box__ctrl {
    top: 260px;
    right: 0;
    left: auto;
  }
  .photo-copyright-widget .cat_photo_box__body {
    width: 100%;
  }
  .photo-copyright-widget .cat_photo_box__body-in {
    max-width: 100%;
    top: 0 !important;
  }
}

@media all and (max-width: 500px) {
  .blog-article .cat_video_box__body-in {
    padding: 90px 15px 25px 43px;
  }
  .blog-article .cat_video_box__body-in .play-btn {
    top: 30px;
    bottom: auto;
  }
  .blog-article-map {
    width: 100%;
  }
  .blog-image__slider {
    max-width: 100%;
  }
  .photo-comments-widget .cat_photo_box__body {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -30px;
    margin-left: auto;
    min-height: 275px;
    height: auto;
    padding-top: 80px;
    padding-bottom: 20px;
  }
  .photo-comments-widget .cat_photo_box__body-in {
    top: 0;
    transform: translate(0, 0);
  }
  .photo-comments-widget .cat_photo_box__slider {
    max-width: 320px;
  }
  .photo-comments-widget .cat_photo_box__ctrl {
    top: 240px;
    right: 15px;
    left: auto;
    bottom: auto;
    z-index: 2;
  }
  .photo-comments-widget .cat_photo_box {
    margin-bottom: 40px;
    padding-bottom: 0;
  }
  .photo-widget .wrapper {
    padding-left: 0;
  }
  .photo-widget .cat_photo_box {
    padding-left: 0;
  }
  .photo-widget .cat_photo_box__ctrl {
    left: auto;
    right: 0;
    bottom: 0;
  }
}

@media all and (max-width: 425px) {
  .blog-article .cat_video_box__body {
    margin-top: -30px;
    margin-right: -15px;
  }
  .blog-article .cat_photo_box {
    padding-left: 0;
  }
  .blog-article .route-distance {
    padding-top: 25px;
  }
  .blog-article .routes_box__body {
    height: 345px;
  }
  .blog-article .routes_box__info--active.routes_box__info--active-mobile {
    max-width: 100%;
    padding: 15px;
    height: auto;
    transform: translate(0%, 100%);
    bottom: 0;
    left: 0;
    top: auto;
    background: #fff;
  }
  .blog-article .routes_box__body--active .routes_box__map, .blog-article .routes_box__body--active .blog-article-map {
    width: 100%;
  }
  .photo-copyright-widget .wrapper {
    padding-left: 0;
  }
  .photo-copyright-widget .cat_photo_box {
    max-width: 100%;
    width: 100%;
  }
  .photo-copyright-widget .cat_photo_box__slider {
    margin-left: -1px;
  }
  .photo-widget .cat_photo_box__slider {
    margin-left: -1px;
  }
  .photo-comments-widget .cat_photo_box__body {
    margin-right: -15px;
  }
}

.fadeInBody {
  opacity: 1;
}

.animated {
  animation-duration: 1200ms;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
}

.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: none;
  }
}

#nav-icon {
  position: relative;
  width: 48px;
  height: 36px;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 16px;
  background: #0b6565;
  opacity: 1;
  left: 16px;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

#nav-icon span:nth-child(1) {
  top: 14px;
}

#nav-icon span:nth-child(2), #nav-icon span:nth-child(3) {
  top: 18px;
}

#nav-icon span:nth-child(4) {
  top: 22px;
}

#nav-icon.open span:nth-child(1) {
  top: 20px;
  width: 0%;
  left: 50%;
}

#nav-icon.open span:nth-child(2) {
  transform: rotate(45deg);
}

#nav-icon.open span:nth-child(3) {
  transform: rotate(-45deg);
}

#nav-icon.open span:nth-child(4) {
  top: 20px;
  width: 0%;
  left: 50%;
}

/* Scroll bottom */
@keyframes bounce {
  0% {
    height: 12px;
  }
  50% {
    height: 16px;
  }
  100% {
    height: 12px;
  }
}

.scrollbt {
  display: block;
  position: absolute;
  right: 0;
  bottom: 30px;
  left: 0;
  width: 18px;
  height: 26px;
  margin: auto;
  z-index: 2;
  cursor: pointer;
}

.mfp-zoom-in {
  /* start state */
  /* animate in */
  /* animate out */
}

.mfp-zoom-in .mfp-with-anim {
  opacity: 0;
  transition: all 0.4s ease-in-out;
  transform: scale(0.8);
}

.mfp-zoom-in.mfp-bg {
  opacity: 0;
  transition: all 0.5s ease-out;
}

.mfp-zoom-in.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: scale(1);
}

.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-zoom-in.mfp-removing .mfp-with-anim {
  transform: scale(0.8);
  opacity: 0;
}

.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

.mfp-video-in {
  /* start state */
  /* animate in */
  /* animate out */
}

.mfp-video-in .mfp-with-anim {
  opacity: 1;
  transition: all 0.4s ease-in-out;
  transform: scale(0.65);
}

.mfp-video-in.mfp-bg {
  opacity: 0;
  transition: all 0.5s ease-out;
}

.mfp-video-in.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: scale(1);
}

.mfp-video-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-video-in.mfp-removing .mfp-with-anim {
  transform: scale(0.65);
  opacity: 1;
}

.mfp-video-in.mfp-removing.mfp-bg {
  opacity: 0;
}

.cat_photo_box__body.opacity,
.cat_video_box__body.opacity {
  opacity: 0;
  transition: opacity ease-in 150ms;
}

.cat_photo_box__body,
.cat_video_box__body {
  opacity: 1;
  transition: opacity ease-out 500ms;
}
