// Contacts

.contact_head {
	width: 50%;
	padding: 130px 0 55px 0;
	text-align: center;

	h1 {
		color: $blue;
		font: bold 30px/35px "Proxima Nova", Arial;
		letter-spacing: -.1px;
	}
}

.contact_map_box {
	position: relative;
	max-width: 985px;
	width: 100%;
	margin: 0 auto 150px;
}

.contact_map {
	width: 505px;
	height: 447px;
}

.contact_map__info {
	position: absolute;
	top: 50%;
	width: 55%;
	height: 225px;
	background: $light-grey2;
	transform: translate(79%, -45%);
}

.map__info_box {
	position: relative;
	top: 50%;
	padding-left: 23%;
	transform: translate(0, -50%);

	.info_ref {
		margin-left: 30px;
		color: $text;
		font: normal 14px/22px "Roboto", Arial;
		text-decoration: none;
		&:hover {
			color: $blue-hover;
			text-decoration: underline;
		}
	}

	.info {
		margin-bottom: 12px;
		&:last-child {
			margin-bottom: 0;
		}
	}

	.info_ico-telephone {
		width: 15px;
		height: 15px;
		display: inline-block;
		background: url('../images/icons/ico-phone-reverse.svg') no-repeat;
		vertical-align: middle;
	}

	.info_ico-mail {
		width: 11px;
		height: 8px;
		display: inline-block;
		background: url('../images/icons/ico-mail.svg') no-repeat;
	}

	.info_ico-address {
		width: 12px;
		height: 11px;
		display: inline-block;
		background: url('../images/icons/ico-house.svg') no-repeat;
	}
}

.info_contact {
	display: none;
}

.contact_faq {
	max-width: 675px;
	width: 100%;
	margin: 0 auto 125px;
	h2 {
		color: $blue;
		font: bold 30px/35px "Proxima Nova", Arial;
		letter-spacing: -.1px;
	}
}

.contact_faq_in {
	margin-top: 50px;
}

.contact_acc__item {
	margin: 0 0 48px 10px;
}

.contact_acc__title h4 {
	display: inline-block;
	position: relative;
	padding: 0 0 0 30px;
	color: $text;
	font: bold 15px/22px "Roboto Cond", Arial;
	text-transform: uppercase;
	letter-spacing: .5px;
	cursor: pointer;
}

.contact_acc__title h4:hover {
	color: $blue-hover;
}

.contact_acc__title h4:before {
	display: block;
	position: absolute;
	top: 10px;
	left: 2px;
	width: 5px;
	height: 1px;
	background-color: $blue;
	content: ' ';
}

.contact_acc__title h4::after {
	display: block;
	position: absolute;
	top: 8px;
	left: 4px;
	width: 1px;
	height: 5px;
	transition: transform 250ms ease-out;
	background-color: $blue;
	content: ' ';
}

.contact_acc__title h4:hover:before {
	left: 1px;
	width: 7px;
}

.contact_acc__title h4:hover::after {
	top: 7px;
	height: 7px;
}

.contact_acc__item--active .contact_acc__title h4::after {
	transform: rotate(90deg);
}

.contact_acc__body {
	display: none;
	padding: 35px 0 0 68px;
	overflow: hidden;
}

.contact_acc__body p {
	max-width: 589px;
	margin: 0 0 15px;
	color: $text;
	font: normal 14px/22px "Roboto", Arial;
	letter-spacing: .2px;
}

.contact_acc__body .cat_specs__item {
	width: 46%;
}

.contact_form {
	position: relative;
	width: 68%;
	margin: 0 auto 150px;

	h2 {
		margin-bottom: 50px;
		color: $blue;
		font: bold 30px/35px "Proxima Nova", Arial;
		letter-spacing: -.1px;
	}

	.input-line {
		max-width: 545px;
		margin-bottom: 30px;
	}

	.checkbox-line {
		margin-top: 40px;
		margin-bottom: 40px;

		label {
			color: $text;
			font: normal 14px/22px "Roboto", Arial;
			letter-spacing: .2px;
		}
	}

	.captcha-wrap {
		margin-bottom: 40px;
	}

	.form-error-box {
		position: absolute;
		left: 300px;
		bottom: 0;
		width: 247px;

		.error-box__item {
			margin-bottom: 10px;
		}
	}
}
