@media all and (max-width: 769px) {

	.cat_video_box,
	.cat_photo_box {
		max-width: 670px;
		margin: 0 auto 165px;
	}

	.cat_video_box__thumb {
		.video_container {
			height: 292px;
		}
	}

	.cat_video_box__body {
		width: 69%;
		transform: translate(0, 22%);
	}

	.cat_photo_box {
		margin: 0 auto 130px;

		img {
			max-height: 320px;
		}
	}

	.cat_photo_box__body {
		width: 62%;
		transform: translate(0, -7%);
	}

	.bt__book {
		margin: 0 0 120px;
	}

}

@media all and (max-width: 650px) {

	.cat_info,
	.cat_specs {
		padding-left: 15px;
	}

	.cat_more__in {
		padding-left: 30px;
	}

	.cat_specs__item {
		margin-right: 4%;
	}

	.cat_specs__item {
		width: 98%;

		dt {
			width: 60%;
		}

		dd {
			width: 40%;
		}
	}

	.dl-wrap {
		border-bottom: 1px solid $light-grey;

		&:first-child {
			border-top: 1px solid $light-grey;
		}
	}

	.cat_more_acc__body {
		.cat_specs__item {
		 width: 93%;
		}

		dd {
			padding-left: 30px;
		}
	}

	.cat_video_box,
	.cat_photo_box {
		padding-left: 15px;
	}

	.cat_photo_box {
    margin: 0 auto 150px;

		img {
			max-height: 240px;
		}
	}

	.cat_video_box__body {
		transform: translate(-5%, 22%);
	}

	.cat_photo_box__body {
		transform: translate(-5%, 0);
	}

	.cat_photo_box__body-in {
		top: 60%;
		padding-left: 45px;
		padding-right: 15px;
	}

	.cat_photo_box__ctrl {
		left: 15px;
	}

	.cat_photo_box__count {
    top: 18%;
    left: 24px;
	}

	.bt__book {
		margin: 0 0 80px;
	}

}

@media all and (max-width: 500px) {

	.cat_photo_box {
    margin: 0 auto 180px;
	}

	.cat_photo_box__ctrl {
		bottom: 27%;
		left: 65%;
		z-index: 2;
	}
}
