@media all and (max-width: 425px) {

	.top {
		top: 10px;
	}

	.mobile-menu {
		top: 12px;
	}

	.mfp-container {
		height: 0;
	}

	.navigation {
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
		.navigation-column {
			height: 100%;
			padding: 20px 30px;
		}

		.news-column {
			display: none;
			top: 100vh;
			left: 0;
			width: 100%;
			height: 0;

			.news-wrap {
				padding: 0 19px;
			}

			.news-line {
				padding: 23px 0;

				a {
					font: bold 15px/22px "Roboto Cond", Arial;
				}
			}
		}
	}

	.navigation.nav-expanded {
		max-height: 960px;
	}

	.top_box__title {
		width: 246px;
		margin-left: 50px;

		.top_box__title-txt {
			font: bold 30px/35px 'Proxima Nova', Arial;
			letter-spacing: -.1px;
		}
	}

	.info_box {
		flex-direction: column;
		margin: 0 0 80px 0;
		padding: 0 15px;
	}

	.info_box__body {
		margin: 50px 0 0 40px;
		h3 {
			width: 246px;
			font: bold 24px/30px "Proxima Nova", Arial;
			letter-spacing: -.1px;
		}
		p {
			width: 230px;
		}
	}

	.cat_list__item {
		margin-bottom: 270px;

		.img {
			width: 246px;
			height: 240px;
		}

		.info {
			width: 245px;
			height: 245px;
			padding: 45px 0 0 43px;
			transform: translate(19%, 83%);
			h3 {
				font: bold 24px/30px 'Proxima Nova', Arial;
			}
			.btn-bordered {
				left: 43px;
			}
		}

		.numb {
			transform: translate(0, -220%);
		}


	}

	.cat_list__more {
		margin-bottom: 0;

		a {
			font: bold 18px/30px 'Proxima Nova', Arial;
		}
	}

	.edge_box__title {
		h3 {
			font: bold 30px/35px "Proxima Nova", Arial;
		}
	}

	.edge_box__in {
		padding-left: 15px;
	}

	.edge_box__item {
		max-width: 290px;
		min-width: 290px;
		padding: 80px 30px 45px 45px;
	}

	.edge_box__ctrl {
		display: block;
	}

	.routes_box {
		margin-bottom: 0;
	}

	.routes_box__title {
		margin: 0 15px 30px;
		h3 {
			font: bold 30px/35px "Proxima Nova", Arial;
		}
	}

	.routes_box__body {
		height: 252px;
	}

	.routes_box__map {
		height: 252px;
	}

	.routes_box__info--active.routes_box__info--active-mobile {
		max-width: 285px;
		height: 290px;
		transform: translate(-50%, 84%);
	}

	.news_box {

		.news__header {
			margin-top: 50px;
			margin-bottom: 40px;

			h3 {
				font: bold 30px/35px "Proxima Nova", Arial;
			}
		}
	}

	.partners_box,
	.comments_box {
		h3 {
			font: bold 30px/35px "Proxima Nova", Arial;
		}
	}

	.comments_box {
		margin-bottom: 47px;
		padding-top: 40px;
		padding-bottom: 55px;
	}

	.comments_box__header {
		margin: 0;
	}

	.comments_box__item {
		.comments_box__image {
			max-width: 160px;
			transform: translate(18%, 26%);
		}

		.comments_box__body {
			width: 100%;
			padding: 85px 30px 45px;
		}
	}

	.comments_controls {
		.comments_box__ctrl {
			top: 175px;
    		left: 68%;
		}

		.comments_box__count {
			top: 275px;
			left: 78%;
		}
	}

	.comments_box__slider {
		padding: 0 15px;
	}

	.comments_box__header,
	.partners_box__header {
		padding-left: 15px;
	}

	.partners_box {
		margin-bottom: 53px;
	}

	.footer__contacts {
		margin-bottom: 40px;

		ul {
			position: absolute;
			display: block;
		}

		.footer__contacts-phone {
			margin-bottom: 7px;
			margin-right: 100px;
		}

		.footer__contacts-email {
			margin-right: 100px;
		}

		.footer__contacts-fb {
			position: absolute;
			top: 1px;
			right: 28px;
		}

		.footer__contacts-in {
			position: absolute;
			top: 1px;
			right: 40px;
		}
	}

	.footer__subscribe {
		padding: 20px 0 54px 15px;
	}

	.footer__credits {
		padding: 0 30px 0 15px;
	}
}
