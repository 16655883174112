.mfp-wrap {
	transform: translateZ(0);
}

.mfp-close-btn-in .mfp-close {
	color: $white;
}

.mfp-close {
	width: 110px;
	height: 40px;
	opacity: 1;
	cursor: pointer;
}

.close-text.mfp-close,
.close-ico.mfp-close {
	position: relative;
	margin: 10px 0;
	font: bold 10px/21px "Roboto Cond";
}

.mfp-container {
	padding: 0;
}

.body-under-popup {
	width: 100%;
	min-height: 686px;
	background: #000;
	opacity: .5;
}

.connect-logo {
	display: none;
}

.connect-popup,
.subscribe-popup {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	max-width: 590px;
	min-height: 415px;
	margin: 0 auto;
	padding: 70px 155px 28px;
	background: $white;

	.con-head,
	.subscr-head {
		font: bold 15px/22px "Roboto Cond";
		color: $text;
		text-transform: uppercase;
		letter-spacing: .5px;
	}
}

.connect-form,
.subscribe-form {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	input {
		margin: 38px 0;
		width: 160px;
		font: bold 18px/24px "Proxima Nova";
		color: $text;
	}

	.input--submit {
		margin-bottom: 55px;
	}
}

.con-social-group {
	.social-ico {
		display: inline-block;
		width: 17px;
		height: 17px;
		margin: 30px 10px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		vertical-align: middle;
	}

	.social-speech {
		background-image: url('../images/icons/ico-speech-bubble.svg');
	}

	.social-skype {
		background-image: url('../images/icons/ico-skype-logo.svg');
	}

	.social-messenger {
		background-image: url('../images/icons/ico-messenger.svg');
	}

	.social-mail {
		height: 11px;
		background-image: url('../images/icons/ico-mail-logo.svg');
	}
}

.connect-error-box {
	position: absolute;
	top: 190px;
}

.connect-error-box,
.subscribe-error-box {
	left: 50%;
	transform: translateX(-50%);
}
