@media all and (max-width: 425px) {
	.comp-info {
		margin-bottom: 75px;

		h3 {
			max-width: 290px;
			margin-bottom: 45px;
			font: bold 24px/30px "Proxima Nova";
			letter-spacing: -.1px;
		}
	}

	.comp_team {
		margin-bottom: 75px;

		h3 {
			margin-bottom: 35px;
			font: bold 24px/30px "Proxima Nova";
			letter-spacing: -.1px;
		}

		.comp__ctrl {
			top: 33%;
		}
	}

	.comp_activity {
		margin-bottom: 75px;

		h3 {
			margin-bottom: 60px;
			margin-left: 0;
			padding: 0 15px;
			font: bold 30px/30px "Proxima Nova";
			letter-spacing: -.1px;
		}
	}

	.comp_activity__block {
		flex-direction: column;

		.activity_box {
			margin-left: 0;
		}

		.activity_ico {
			margin: 0 0 35px 5px;
		}
	}
}
