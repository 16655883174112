/* Bg */
.bg-main {
	background: url('../images/bg-main.jpg') center bottom no-repeat;
	background-size: cover;
}


/* Header */
.top {
	position: absolute;
	top: 30px;
	right: 0;
	left: 0;
	margin: 0 auto;
	z-index: 6;
}

.top__logo {
	margin-left: 5%;
	float: left;
}

.top__logo img {
	display: block;
	width: 100%;
	max-width: 110px;
	height: auto;
}

.top__lang ul {
	margin: 0;
	list-style: none;
}

.top__lang ul li {
	display: inline-block;
	margin-right: 15px;
	vertical-align: middle;
}

.top__lang ul li.active a {
	color: $blue;
}

.top__lang ul li a {
	color: $text;
	font: normal 13px/27px "Roboto";
	letter-spacing: .2px;
	text-decoration: none;
}

.top__lang ul li a:hover {
	color: $blue-hover;
}

.top__nav h5 {
	margin: 0 0 25px;
	color: $gray;
	font: normal 9px/100% "Roboto", Arial;
}

.top__nav ul {
	margin: 0;
	list-style: none;
}

.top__nav ul li {
	margin: 0 0 2px;

	&.is-secondary {
		a {
			color: #fff;
		}
		&.active a {
			color: #26afaf;
		}
	}

	&:last-child {
		margin: 0 0 10px;
	}
}

.top__nav ul li a {
	color: $text;
	font: bold 10px/21px "Roboto Cond";
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: uppercase;
}

.top__nav ul li.active a {
	color: $blue;
}

.top__nav ul li a:hover {
	color: $blue-hover;
}

.top__call {
	width: 110px;
}

.navigation {
	.news-column {
		.news-line {
			opacity: 0;
		}
	}
}




/* Footer */
.footer {
	max-width: 930px;
	margin: 0 auto;
	padding-bottom: 30px;
}

.footer__contacts {
	margin: 0;
	float: left;
}

.footer__contacts ul {
	margin: 0;
	list-style: none;
}

.footer__contacts ul li {
	display: inline-block;
	margin: 0 32px 0 0;
	color: $text;
	font: normal 14px/100% "Roboto", Arial;

	vertical-align: middle;
}

.footer__contacts ul li a {
	color: inherit;
	text-decoration: none;
}

.footer__contacts ul li a:hover {
	color: $blue-hover;
	text-decoration: underline;
}

.footer__contacts-in a {
	display: block;
	width: 12px;
	height: 12px;
	background: url('../images/ico-instagram-g.png');
	background-size: 12px 12px;
	text-indent: -9999px;
}

.footer__contacts .footer__contacts-fb {
	margin-left: -5px;
}

.footer__contacts-fb a {
	display: block;
	width: 12px;
	height: 12px;
	background: url('../images/ico-fb-g.png') center 0 no-repeat;
	background-size: 6px 12px;
	text-indent: -9999px;
}

.footer__contacts-in a:hover,
.footer__contacts-fb a:hover {
	opacity: .8;
}

.footer__info {
	float: right;
}

.footer__info p {
	display: inline-block;
	width: 225px;
	margin: 0 40px 0 0;
	color: $text;
	font: normal 14px/22px "Roboto", Arial;
	letter-spacing: .2px;
	text-align: right;
	vertical-align: middle;
}

.footer__info .btn {
	vertical-align: middle;
}

.footer__pay {
	float: left;
}

.footer__pay ul {
	margin: 0;
	list-style: none;
}

.footer__pay ul li {
	display: inline-block;
	margin: 0 20px 0 0;
	text-indent: -9999px;
	vertical-align: middle;
}

.footer__pay ul li:last-child {margin-right: 0;}

.footer__pay ul li.pp {
	width: 54px;
	height: 14px;
	background: url('../images/logo-paypal.png');
	background-size: 54px 14px;
}

.footer__pay ul li.vi {
	width: 31px;
	height: 11px;
	background: url('../images/logo-visa.png');
	background-size: 31px 11px;
}

.footer__pay ul li.mc {
	width: 82px;
	height: 16px;
	background: url('../images/logo-mastercard.png');
	background-size: 82px 16px;
}

.footer__copy {
	float: right;
}

.footer__copy p {
	margin: 7px 0 0;
	color: $gray;
	font: normal 9px/100% "Roboto", Arial;
	letter-spacing: .2px;
}

.footer__dev {
	text-align: center;
}

.footer__dev ul {
	list-style: none;
}

.footer__dev ul li {
	display: inline-block;
	margin: 0 9px;
	color: $gray;
	font: normal 9px/100% "Roboto", Arial;
	vertical-align: middle;
}

.footer__dev ul li a {
	display: inline-block;
	margin-left: 4px;
	color: inherit;
	text-decoration: none;
}

.footer__dev ul li a:hover {
	color: $blue-hover;
	text-decoration: none;
}


/* Top box */
.top_box {
	position: relative;
	width: 100%;
	height: 100%;
	margin: 0 0 120px;
}

.top_box__shadow-top:before {
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	width: 100%;
	height: 30%;
	background: linear-gradient(to bottom, rgba(255 , 255 , 255 , 1) 0%, rgba(255 , 255 , 255 , 0) 100%);
	content: ' ';
	z-index: 1;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$whitefff', endColorstr='#00ffffff',GradientType=0);
	pointer-events: none;
}

.top_box__shadow-bt::after {
	display: block;
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 30%;
	background: linear-gradient(to bottom, rgba(255 , 255 , 255 , 0) 0%, rgba(255 , 255 , 255 , 1) 100%);
	content: ' ';
	z-index: 1;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='$whitefff',GradientType=0);
	pointer-events: none;
}

.opacity-block {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: $white;
		content: ' ';
		pointer-events: none;
}

.top_box__title {
	position: absolute;
	right: 0;
	bottom: 120px;
	left: 0;
	z-index: 2;
}

.top_box__title-txt {
	max-width: 560px;
	width: 100%;
	margin: 0 auto;
	color: $blue;
	font: bold 45px/47px "Proxima Nova", Arial;
	letter-spacing: -.3px;
}


.top_cat_info {
	position: absolute;
	right: 0;
	bottom: 20%;
	left: 0;
	width: 65%;
	max-width: 680px;
	margin: 0 auto;
	z-index: 2;
}

.top_cat_info h5 {
	margin: 0 0 13px;
	color: $gray;
	font: normal 11px/100% "Roboto", Arial;
}

.top_cat_info h1 {
	margin: 0 0 5px;
	color: $blue;
	font: bold 45px/47px "Proxima Nova", Arial;
}

.top_cat_info h4 {
	margin: 0 0 27px;
	color: $text;
	font: bold 15px/100% "Roboto Cond", Arial;
	letter-spacing: .4px;
	text-transform: uppercase;
}

/* Info box */
.info_box {
	display: flex;
	justify-content: center;
	margin: 0 10px 165px;
}

.info_box__left {
	align-self: stretch;
	margin: 0 58px 0 0;
}

.info_box__left p {
	width: 70px;
	margin: 8px 0 0 ;
	color: $gray;
	font: normal 11px/18px "Roboto", Arial;
}

.info_box__body {
	max-width: 600px;
}

.info_box__body h3 {
	margin: 0 0 35px;
	color: $blue;
	font: bold 30px/35px "Proxima Nova", Arial;
}

.info_box__body p {
	color: $text;
	font: normal 14px/22px "Proxima Nova", Arial;
}


/* Edge box */
.edge_box {
	position: relative;
	margin: 0 0 35px;
	padding: 60px 0 100px;
	background: url('../images/bg-edge.jpg') no-repeat;
	background-size: cover;
}

.edge_box__items {
	display: flex;
	flex-wrap: wrap;
}

.edge_box__in {
	max-width: 845px;
	margin: 0 auto;
	overflow: hidden;
}

.edge_box__title {
	position: relative;
	margin: 0 0 50px 30px;
	z-index: 2;
}

.edge_box__title h3 {
	color: $blue;
	font: bold 45px/47px "Proxima Nova", Arial;
	letter-spacing: -.1px;
}

.edge_box__item {
	position: relative;
	max-width: 380px;
	min-width: 380px;
	min-height: 270px;
	margin: 20px;
	padding: 63px 60px 35px 85px;
	float: left;
	background-color: $white;
	z-index: 5;
}

.edge_box__item .numb {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 50px;
	height: 50px;
	background-color: $blue;
	color: $white;
	font: normal 20px/50px "Proxima Nova", Arial;
	text-align: center;
}

.edge_box__item h4 {
	margin: 0 0 15px;
	color: $text;
	font: bold 15px/22px "Roboto Cond", Arial;
	letter-spacing: .5px;
	text-transform: uppercase;
}

.edge_box__item p {
	color: $text;
	font: normal 14px/22px "Roboto", Arial;
	letter-spacing: .2px;
}


/* Routes box */

.routes_box {
	margin-bottom: 125px;
}

.routes_box.routes_box--tabbed {
	margin-bottom: 377px;
}

.routes_box__in {
	max-width: 845px;
	margin: 0 auto 45px;
}

.routes_box__title {
	margin: 0 30px 30px;
}

.routes_box__title h3 {
	max-width: 570px;
	margin: 0 0 30px;
	color: $blue;
	font: bold 45px/47px "Proxima Nova", Arial;
	letter-spacing: -.1px;
}

.routes_box__title p {
	max-width: 570px;
	color: $text;
	font: normal 14px/22px "Roboto", Arial;
	letter-spacing: .2px;
}

.routes_box__legend {
	margin: 0 auto 35px;
}

.routes_box__legend ul {
	margin: 0 0 0 7px;
	list-style: none;
}

.routes_box__legend ul li {
	display: inline-block;
	margin: 0 55px 0 0;
	padding: 0 0 0 35px;
	color: $gray;
	font: normal 11px/27px "Roboto", Arial;
	vertical-align: middle;
}

.routes_box__legend ul li.ico1 {
	background: url('../images/ico-map_legend1.png') 0 center no-repeat;
	background-size: 17px 17px;
}

.routes_box__legend ul li.ico2 {
	background: url('../images/ico-map_legend2.png') 0 center no-repeat;
	background-size: 15px 15px;
}

.routes_box__body {
	position: relative;
	height: 345px;
	width: 100%;
	margin: 0 0 25px;
}

.routes_box__map,
.blog-article-map {
	position: relative;
	width: 85%;
	height: 345px;
	float: left;
	transition: all 350ms ease-out;
	z-index: 1;
}

.routes_box__body--active {
	.routes_box__map,
	.blog-article-map {
		width: 50%;
	}
}

.routes_box__body--active.routes_box__body--active-mobile .routes_box__map {
	width: 100%;
}

.routes_box__info {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 350px;
	padding: 25px 0 0 50px;
	transform: translate3d(-200%, -50%, 0);
	transition: all 350ms ease-out;
	opacity: 1;
	overflow: hidden;
}

.routes_box__info--active {
	transform: translate3d(0, -50%, 0);
	opacity: 1;
}

.routes_box__info--active.routes_box__info--active-mobile {
	max-width: 605px;
	width: 100%;
	padding: 0;
	transform: translate(-47%, 158%);
}

.routes_box__info h4 {
	margin: 0 0 25px;
	color: $text;
	font: bold 15px/22px "Roboto Cond", Arial;
	text-transform: uppercase;
}

.routes_box__info p {
	margin: 0 0 30px;
	color: $text;
	font: normal 14px/22px "Roboto", Arial;
	letter-spacing: .2px;
}

.routes_box__info-btns {
	text-align: left;
}

.routes_box__info-btns a,
.routes_box__info-btns button {
	display: inline-block;
	padding: 0 25px 0 0;
	color: $text;
	font: bold 10px/21px "Roboto", Arial;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: uppercase;
}

.routes_box__info-btns a:hover,
.routes_box__info-btns button:hover {
	color: $blue-hover;
	text-decoration: none;
}

.routes_box__info-more {
	background: url('../images/ico-arr-r-g.png') right center no-repeat;
	background-size: 9px 6px;
}

//.routes_box__info-more {
//	display: none !important;
//}

.routes_box__info-close {
	position: relative;
	float: right;
	background-color: transparent;
}

.routes_box__info-close::after {
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 1px;
	width: 9px;
	height: 7px;
	margin: auto;
	transition: all 150ms ease-out;
	background: url('../images/ico-close-g.png') no-repeat;
	background-size: 9px 7px;
	content: ' ';
}

.routes_box__info-close:hover::after {
	transform: scale(1.2);
}

/* News */
.news_box {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
	max-width: 800px;
	margin: 0 auto 135px;

	.news__header {
		width: 100%;
		margin-top: 100px;
		margin-bottom: 50px;

		h3 {
			font: bold 45px/47px "Proxima Nova", Arial;
			letter-spacing: -.1px;
			color: $blue;
		}
	}
}

.news__column {
	width: 100%;
	max-width: 385px;

	.news__link {
		display: block;
		width: 100%;
		height: 244px;
		text-decoration: none;
	}

	.news__image {
		height:244px;
		background-position: 50% 0;
		background-repeat: no-repeat;
		background-size: cover;
		margin-bottom: 37px;

		a{
			display: block;
			width:100%;
			height:100%;
		}

		img {
			vertical-align: top;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.news__head {
		display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
		.news__date {
			min-width: 80px;
			margin-right: 20px;
			font: normal 11px/27px "Roboto", Arial;
			color: $gray;
		}

		.news__title {
			h4 {
				margin-top: 2px;
				font: bold 15px/22px "Roboto Cond", Arial;
				color: $text;
				letter-spacing: .5px;
				margin-bottom: 25px;
			}

			a {
				text-decoration: none;
			}
		}

		.news__content {
			max-width: 270px;
			color: $text;
			font: normal 14px/22px "Roboto", Arial;
			letter-spacing: .2px;
		}
	}
}
.news__body {
  width: 100%;
}

/* Comments */
.comments_box {
	position: relative;
	margin-bottom: 130px;
	padding-top: 77px;
  padding-bottom: 150px;
	background: $light-grey2;

	&__header {
		width: 100%;
		max-width: 800px;
		margin: 0 auto 53px;

		h3 {
			font: bold 45px/47px "Proxima Nova", Arial;
			letter-spacing: -.1px;
			color: $blue;
		}
	}

	&__slider {
		width: 100%;
		max-width: 800px;
		margin: 0 auto;
	}

	&__count {
		display: block;
		color: $gray;
		font: normal 9px/27px "Roboto", Arial;
		text-align: center;

		.index {
			display: inline-block;
			color: $blue;
			font: 300 20px/35px "Proxima Nova", Arial;
			vertical-align: middle;
		}
	}
}

.comments_box__item {
	position: relative;
	float: right;

	.comments_box__image {
		position: absolute;
		top: 45px;
    left: -168px;
		width: 200px;
		height: 200px;
		background-position: center;
		background-size: cover;
	}

	.comments_box__body {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 545px;
		height: 295px;
		padding: 45px 85px 45px 130px;
		background: $white;

		h5 {
			margin-bottom: 14px;
			color: $gray;
			font: normal 11px/27px "Roboto", Arial;
			letter-spacing: .3px;
		}

		p {
			margin-bottom: 35px;
			color: $text;
			font: normal 14px/22px "Roboto", Arial;
			letter-spacing: .2px;
		}

		.more-comments {
			align-self: flex-start;
			position: relative;
		}
	}
}

.comments_controls {
	position: relative;
	max-width: 800px;
	margin: 0 auto;

	.comments_box__ctrl {
		position: absolute;
    left: 32%;
    top: 30px;
		z-index: 1;
	}

	.comments_box__count {
		position: absolute;
		top: -34px;
		left: 11%;
	}
}

.partners_box {
	max-width: 845px;
	width: 100%;
	margin: 0 auto 100px;

	&__header {
		width: 100%;
		margin: 0 0 30px;

		h3 {
			font: bold 45px/47px "Proxima Nova", Arial;
			letter-spacing: -.1px;
			color: $blue;
		}
	}
}

.partners_box__items {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto;

	.partners_box__item {
		align-self: center;
		max-width: 100px;
		width: 100%;
		margin: 23px 50px;

		img {
			max-width: 100px;
		}
	}
}

.navigation-column {
		display: flex;
	  flex-direction: column;
	  flex-wrap: wrap;
	  justify-content: space-between;
	  align-items: flex-end;
		-webkit-overflow-scrolling: touch;

		.top__lang {
			margin-right: 10px;
		}

		.top__nav {
			position: fixed;
			right: 0;
			top: 50%;
			transform: translate(0, -50%);
		}

		.btn-green {
			width: 123px;

			.arr-r {
				margin: 0;
				padding: 0 20px 0 0;
			}
		}
		.list-unstyled {
			margin-left: 15px;
		}
		h5 {
			@extend .list-unstyled;
		}


		.top__call {
			position: fixed;
			bottom: 35px;

			.call {
				color: $text;
				font: bold 10px/21px "Roboto Cond";
				letter-spacing: 1px;
				text-decoration: none;
				text-transform: uppercase;

				span {
					display: inline-block;
					width: 10px;
					height: 15px;
					margin: 0 20px 2px;
					vertical-align: middle;
					svg {
						overflow: visible;
					}
				}

				&:hover {
					color: $blue-hover;
				}
			}
		}
	}

.navigation-column.index-navigation-column {
	height: 89vh;
}

.news-column {
	display: none;
}

.edge_box__ctrl {
	display: none;
}

.footer__subscribe {
	padding-top: 52px;
	padding-bottom: 100px;

	.btn-more {
		position: relative;
	}

}

.arrow {
	position: absolute;
	display: inline-block;
  top: 0;
  right: -20px;
	width: 11px;

	svg {
		overflow: visible;
		backface-visibility: hidden;
	}
}

.opacity-body {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: $opacity-block;
	opacity: 0;
	z-index: 0;
}

// Booking
.section-booking {

	.btn-bordered,
	.btn-green {
		.arrow {
			top: 16px;
		}
	}

	.cat-more {
		position: relative;
		.arrow {
			top: 11px;
		}
	}
}
