.subscribe-popup {
	padding: 62px 155px 10px;
	min-height: 300px;

	.subscribe-error-box {
		position: absolute;
		top: 180px;
	}
}

.subscribe-form {
	input {
		width: 340px;
		margin: 35px 0 ;
	}
}

form {
	.errorClass {
		color: $error;
	}
}
